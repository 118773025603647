@import "../../../mixins.scss";

$font: "Helvetica Neue", Arial, Helvetica, sans-serif;
.ContactEdit {
  display: none;

  .dragndrop-container {
    height: 300px;
    .inner.table {
      .cell:last-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .error {
    label {
      color: red;
    }
  }

  #phone_number_flag {
    width: 54px;
    border-radius: 3px;
    position: absolute;
    margin: 13px 15px;
    height: 28px;
    & + span {
      position: absolute;
      height: 54px;
      color: #555;
      text-align: right;
      font: 15px/54px $baseFont;
      left: 84px;
    }
  }
  #phone_number {
    padding-left: 110px !important;
  }

  @include for-size(tablet-landscape-up) {
    display: block;
    min-width: 410px;
    position: relative !important;

    > footer {
      border: 0 !important;
      padding: 0 !important;
      position: relative !important;
    }
  }

  &.shown {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    z-index: 100;
  }

  &__Scroll {
    overflow-y: scroll;
    padding: 30px;
    height: calc(100% - 81px - 60px);

    > button {
      color: #4c4c4c !important;
      height: 40px !important;
      font-size: 18px !important;
      line-height: 40px !important;
      margin-left: -15px;
      width: 40px !important;
    }

    > h1 {
      color: #242424;
      font: 300 30px/40px $font;
      margin: 8px 0 36px;
    }

    > div {
      font-size: 0;
    }

    > div:not(:first-child) {
      margin-top: 20px;
    }

    @include for-size(tablet-landscape-up) {
      > button,
      > h1 {
        display: none !important;
      }

      > div:first-of-type {
        margin-top: 0 !important;
      }
    }
  }

  button > i {
    display: block;
  }

  &__ImportButtons {
    margin-top: 35px !important;
  }

  > footer {
    padding: 15px 30px;
    border-top: solid 1px #dfdfdf;
    background: #fff;
    > button {
      font-weight: 500;
      margin: 0 !important;
      width: 50%;
    }
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    label {
      flex-grow: 1;
      width: 100%;
    }
    :not(:first-child) {
      flex: 1;
    }
    input:last-child {
      margin-left: 15px;
    }
  }

  .columns_2 {
    display: flex;
    flex-direction: row;
    label {
      flex-grow: 1;
      width: 100%;
    }
    > div {
      flex-grow: 1;
      @include for-size(tablet-landscape-up) {
        &:first-child {
          margin-right: 15px;
        }
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }

  button {
    background: none;
    border-radius: 25px;
    color: #9e9e9e;
    cursor: pointer;
    font: 16px $font;
    height: 50px;
    width: 100px;
  }

  .csv-upload-hint {
    display: block;
    font-size: 12px;
    color: #999;
    margin-top: 10px;
    a {
      color: $brandColorLighten;
      text-decoration: none;
      font-weight: 600;
    }
  }
}
