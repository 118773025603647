@import '../../../mixins.scss';

.IntroSlider {
    text-align: center;
    font-family: $baseFont;
    &__TextContainer {
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 296px;
        width: 377px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__TextItem {
        padding: 0 61px;
        header {
            font-size: 20px;
            line-height: normal;
            color: #242424;
            font-weight: bold;
            margin-bottom: 20px;
        }
        article {
            font-size: 15px;
            line-height: 21px;
            color: #7E7E7E;
        }
    }
    &__Navigator {
        display: flex;
        width: 150px;
        margin: 0 auto 50px;
        padding: 40px 0 0 35px;
        li {
            margin: 0 25px 0 0;
            padding: 0;
            list-style: none;
            border-radius: 5px;
            display: block;
            background-color: #E0E0E0;
            width: 6px;
            height: 6px;
            border: 2px solid #fff;
            cursor: pointer;
            &.active {
                background-color: transparent;
                border-color: $brandColor;
            }
        }
    }
    .skip {
        color: $brandColor;
        font-size: 16px;
    }
    .bottomMargin {
        margin-bottom: 20px;
    }
}