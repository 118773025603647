@import "../../mixins.scss";

aside.AttachmentPanel {
  background: #fff;
  border-top: solid 1px #e3e3e3;
  height: 0;
  left: 0;
  line-height: normal;
  max-height: 30vh;
  overflow-y: auto;
  right: 0;
  transition: all 0.5s ease;
  z-index: 100;
  &.openDown {
    top: 100%;
    position: relative;
  }
  &.openTop {
    bottom: 100%;
    position: absolute;
  }
  &.shown {
    border-bottom: solid 1px #e3e3e3;
    height: auto;
  }

  .flex {
    align-items: center;
  }

  .filePreview {
    font: bold 14px/80px $baseFont;
    height: 80px;
    width: 80px;
  }

  .imageDetails {
    height: 70px;
    padding: 0 0 0 20px;
    width: calc(100% - 80px);
    > div {
      width: calc(100% - 80px);
    }
  }

  > i {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    color: #7a7a7a;
  }

  > .error-msg {
    position: absolute;
    bottom: 3px;
    color: #dd3b26;
    width: 70%;
    text-align: center;
  }

  > .loaderContainer {
    position: absolute;
    left: 47%;
    top: 37%;
  }

  .icon-delete {
    color: #555555;
    cursor: pointer;
  }

  .dragndrop-container {
    margin: 40px;
    overflow-y: auto !important;
    button.removeFile {
      border-left: 1px solid #e3e3e3;
      border-radius: 0;
      height: 40px;
      width: 60px;
      &:hover {
        background: none;
        color: #000;
      }
    }
  }
}
