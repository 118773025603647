@import "../../../mixins.scss";

.ChatSettings {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 350px;
  border-radius: 4px;
  margin: 8px 8px 8px 2px;
  padding: 30px;
  background-color: white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);

  @include for-size(phone-only) {
    width: 230px;
  }

  .select > div:nth-child(3) > div {
    height: 200px !important;
  }

  &__Label {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    align-items: center;

    color: #000000;

    margin-bottom: 8px;
  }

  &__LabelDanger {
    cursor: pointer;
    font-size: 16px;
    text-align: right;
    width: 100%;
    color: #dd3b26;
    margin-top: 34px;
  }

  &__ColorPickerButton {
    width: 26px;
    height: 26px;
    border-radius: 15px;
    margin: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__ColorsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 13px;
  }

  &__ColorItemOuter {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__ColorItemInner {
    border: 3px solid white;
    width: 26px;
    height: 26px;
    border-radius: 16px;
  }
}
.schedule-window-open {
  left: auto !important;
  right: 365px;
}
