@import "../../../mixins.scss";

%btn {
  display: inline-block;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  line-height: 50px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  text-align: center;
  background-color: $brandColor;
  padding: 0 40px;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;

  &.full-width {
    display: block;
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    background-color: lighten($brandColor, 3%);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.ConfirmModal > .inner > div {
  padding: 35px;
  text-align: center;
  border-top: solid 1px #e8e8e8;
  p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000;
    font-size: 18px;
  }
  .btn-primary {
    @extend %btn;
    color: #fff;

    &:hover {
      background-color: lighten($brandColor, 3%);
    }
  }

  .btn-secondary {
    @extend %btn;
    background-color: transparent !important;
    border: 2px solid #e0e0e0;
    color: $color-text;
    line-height: 46px;

    &:hover {
      border-color: #ccc;
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
      opacity: 0.9;
      outline: 1px dotted;
    }

    &.is-select {
      padding: 0 25px;
      display: inline-flex;
      align-items: center;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        margin-left: 8px;
        display: inline-block;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
      }
    }

    &.is-cancel {
      border: 0;
      color: #9e9e9e;

      &:hover {
        color: $color-text;
      }
    }

    &.is-text {
      border: 0;
      padding: 0;
    }
    &.is-danger {
      color: $color-danger;
    }
    &.is-primary {
      color: $brandColor;
    }
    &.is-right {
      float: right;
      min-width: 150px !important;
    }
  }

  > span.error {
    &:first-of-type {
      margin-top: 1em;
    }
    color: $color-danger;
    display: block;
  }
  > footer {
    margin-top: 35px;
  }
}
