@import "../../../mixins.scss";

.ProfileGeneral {
  .button.is-primary {
    margin-right: 30px;
  }
}

.input {
  background: #f5f5f5;
  border: solid 2px transparent;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 20px;
  font: 15px/18px $baseFont;
  outline: none;

  &[disabled] {
    background: transparent !important;
    color: #7a7a7a !important;
    padding-left: 0 !important;
  }
}
.highlight-input {
  &.input {
    // background: #F5F5F5 !important;
    border: solid 2px transparent;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 0px 16px 16px;
    font: 15px/18px $baseFont;
    outline: none;
    &[disabled] {
      background: transparent !important;
      color: #7a7a7a !important;
      padding-left: 0 !important;
    }
  }
}
.select {
  -webkit-appearance: initial;
  position: relative;
  margin-bottom: 20px;
}

.button {
  background: transparent;
  border: 0;
  border-radius: 30px;
  color: #9e9e9e;
  cursor: pointer;
  font: 500 16px/50px $baseFont;
  height: 50px;

  &[disabled] {
    cursor: default !important;
    opacity: 0.5;
  }

  &.is-primary {
    background: #004b87;
    color: #fff;
    min-width: 130px;
  }
}

label {
  color: #000;
  display: block;
  font: 500 13px $baseFont;
  margin-bottom: 10px;
  margin-top: 30px;
  &:first-child {
    margin-top: 0 !important;
  }
}
