@import '../../mixins.scss';

.Sidebar {
  align-items: stretch;
  display: flex;
  height: 100%;

  > aside {
    background: $brandColor;
    border-bottom: solid 5px $brandColorDarken;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-left: -315px;
    overflow: auto;
    transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
    min-width: 315px;
    max-width: 315px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    i {
      width: 60px;
      height: 60px;
      display: inline-block;
      line-height: 60px;
      text-align: center;
      &.icon-close {
        font-size: .5em;
      }
    }

    > header {
      border-bottom: solid 1px rgba(255,255,255,.2);
      > button {
        color: #fff;
        float: left;
        font-size: 2rem;
        margin-right: 20px;
      }
      > a {
        display: block;
      }
    }

    > ul {
      color: #fff;
      font-size: 16px;
      line-height: 60px;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        padding: 0 20px;
      }
    }

    > footer {
      border-top: solid 1px rgba(255,255,255,.2);
      color: rgba(255,255,255,.56);
      font-size: 13px;
      margin: 0 30px;
      padding: 24px 0;

      > a {
        color: #fff;
        float: right;
        text-decoration: none;
      }
    }

    > button {
      background: none;
      border: 0;
      color: #fff;
      font-size: 1.25em;
      height: 70px;
      padding: 0;
      width: 70px;
      outline: none;
      cursor: pointer;

      &.disabled {
        opacity: .25 !important;
      }

      &:hover:not(.disabled) {
        font-size: 1.5em;
        opacity: 1;
      }

    }
  }

  > section {
    flex-grow: 1;
  }

  &.open {
    overflow: hidden;
    &::after {
      background: rgba(0,0,0,.85);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
    }
    > aside {
      margin-left: 0 !important;
      z-index: 1000;
    }
  }
}