@import "../../mixins.scss";

.ContactProfile {
  display: none;
  padding: 30px;
  z-index: 1002;
  overflow-y: auto;

  @include for-size(tablet-landscape-up) {
    align-items: flex-start;
    display: block;
    position: relative !important;
    width: 100%;

    > button {
      display: none;
    }

    > header {
      flex-direction: row !important;
      margin-top: 0 !important;
      text-align: left !important;
      > img {
        float: left !important;
        margin: 0 30px 0 0 !important;
        width: 120px !important;
        height: 120px !important;
        min-height: 120px !important;
        line-height: 120px !important;
        min-width: 120px;
      }
    }

    > footer {
      border-top: 0 !important;
      text-align: left !important;

      > button:after {
        content: none !important;
      }
    }
  }

  button > i {
    display: block;
  }

  > button {
    color: #4c4c4c !important;
    height: 40px !important;
    font-size: 18px;
    line-height: 40px;
    margin-left: -15px;
    width: 40px !important;
  }

  i.icon-back {
    font-size: 18px;
    line-height: 40px;
  }

  &.shown {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    max-height: 100%;
  }

  > footer {
    text-align: center;
    border-top: solid 1px #dfdfdf;
    padding: 40px 0;

    > button {
      background: none;
      border: 2px solid #ebebeb;
      border-radius: 50%;
      cursor: pointer;
      font-size: 1rem;
      height: 54px;
      width: 54px;
      margin-right: 30px;
      position: relative;

      @include for-size(tablet-landscape-up) {
        margin-right: 10px;
      }

      &.text-btn::after {
        content: "Text";
      }

      &.edit-btn::after {
        content: "Edit";
      }

      &.delete-btn::after {
        content: "Delete";
      }

      &:after {
        color: #707070;
        font: 12px $baseFont;
        text-align: center;
        text-transform: uppercase;
        display: block;
        margin-top: 12px;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 34px;
    text-align: center;
    @include for-size(tablet-landscape-up) {
      flex-grow: 0;
    }
    > img {
      width: 140px;
      height: 140px;
      min-height: 140px;
      background: #f4f4f4;
      border-radius: 50%;
      display: block;
      margin: 0 auto 30px;
      text-align: center;
      color: #242424;
      overflow: hidden;
      line-height: 140px;
      font-family: "icomoon" !important;
      speak: none;
      font-size: 4rem;
      letter-spacing: 15px;
    }

    h1,
    h2 {
      margin: 0;
      font-weight: 400;
    }

    h1 {
      color: #242424;
      font-family: $baseFont;
      font-size: 26px;
      line-height: 32px;
    }

    h2 {
      color: #7a7a7a;
      display: inline-block;
      font-family: $baseFont;
      font-size: 18px;
      line-height: 20px;
      margin-top: 10px;
    }

    label {
      border: 1px solid #ebebeb;
      border-radius: 15px;
      color: #7e7e7e;
      display: inline-block;
      font-family: $baseFont;
      font-size: 10px;
      line-height: 20px;
      margin: 0;
      margin-right: 5px;
      padding: 0px 8px;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }

  > div > div {
    margin-bottom: 30px;
    text-align: center;
    @include for-size(tablet-landscape-up) {
      text-align: left;
    }
    > h3 {
      color: #a1a1a1;
      font: 12px $baseFont;
      margin-bottom: 10px;
      margin-top: 40px;
    }
    > p {
      word-break: break-all;
      color: #242424;
      font: 14px $baseFont;
    }
  }
}
.rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.left_col {
  width: 45%;
  margin-right: 17px !important;
}
.right_col {
  max-width: 50%;
}
