@import "../../mixins.scss";

.Header {
  border-bottom: 1px solid #e3e3e3;
  height: 60px;
  display: flex;
  justify-content: space-between;
  position: relative;

  @include for-size(tablet-landscape-up) {
    height: 70px;
  }

  &__Right {
    display: flex;
  }

  &__Logo {
    color: #121212;
    font: 600 13px $baseFont;
    text-decoration: none;
    z-index: -1;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8px;

    width: 100%;
    text-align: center;

    @include for-size(tablet-landscape-up) {
      font-size: 19px;
      line-height: 70px;
      bottom: auto;
      transform: none;
      left: auto;
    }

    & > strong {
      font-weight: 400;
    }
  }

  &__Logo1 {
    margin: 8px 0;
    // height: px;
    max-height: 54px;
    align-self: center;

    @include for-size(tablet-landscape-up) {
      margin: 8px 26px;
      height: 50%;
    }
  }

  &__Button {
    border: 0;
    color: #fff;
    cursor: pointer;
    float: left;
    font-size: 12px;
    height: 60px;
    outline: none;
    padding: 0;
    position: relative;
    width: 60px;

    > i {
      vertical-align: middle;
    }

    @include for-size(tablet-landscape-up) {
      display: none;
      height: 70px;
      width: 70px;

      &:after {
        content: initial;
      }
    }
  }

  &__ProfileButton {
    background: none;
    border: 0;
    color: #242424;
    font: 500 16px/60px $baseFont;
    float: right;
    margin-right: 15px;
    padding: 0 12px;

    > i {
      display: inline;
      vertical-align: middle;
      font-size: 15px;
      @include for-size(tablet-landscape-up) {
        display: none;
      }
    }

    > span {
      display: none;
      @include for-size(tablet-landscape-up) {
        display: inline;
        line-height: 70px;
      }
    }
  }

  &__ProfileButtonHidden {
    @include for-size(phone-only) {
      display: none;
    }
  }

  &__Unread {
    display: flex;
    align-items: center;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #dddddd;
    height: 36px;
    position: relative;

    @include for-size(phone-only) {
      margin-right: 0;
      border-width: 0;
    }

    > i {
      font-size: 21px;
      color: #004b87;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background-color: #ef4902;
      position: absolute;
      right: 14px;
      top: 0;

      > span {
        font-size: 10px;
        line-height: 10px;
        font-weight: bold;
        color: white;
      }
    }
  }
}
