@import '../../../mixins.scss';

.SettingsModal {
  .inner {
    min-height: 500px;
    overflow-y: scroll;
  }

  .content {
    padding: 40px 60px;
    padding-top: 0;
    box-sizing: border-box;

    @include for-size(phone-only) {
      padding: 30px;
      padding-top: 0;
    }

    .themeRow {
      display: flex;
      position: relative;

      @include for-size(phone-only) {
        flex-direction: column;
      }

      .themeSelector {
        min-width: 50%;
        margin-right: 16px;
      }
    }

    h3 {
      color: #A1A1A1;
      font-size: 12px;
      padding-bottom: 10px;
      margin-bottom: 30px;
      font-weight: 400;
      border-bottom: 1px solid #DBDBDB;
      font-family: $baseFont;
    }

    span {
      display: inline-block;
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 8px;
      font-family: $baseFont;
    }

    .switcher {
      margin-right: 30px;
    }

    footer {
      margin-top: 50px;

      button:last-child {
        color: #9E9E9E;
        width: 100px;
        font-size: 16px;
      }
    }
  }

  .picker {
    position: absolute;
    right: 0;
    top: 24px;
    z-index: 100;

    @include for-size(phone-only) {
      position: relative;
      margin-bottom: 30px;
      top: 0;
    }
  }
}
