@import "../../../mixins.scss";
.ReportsModal {
  .inner > section {
    background: #f6f6f6;
    flex-direction: column;
    overflow: auto;
    @include for-size(tablet-landscape-up) {
      max-height: calc(100% - 208px);
      padding: 30px 40px;
      position: relative;
    }
  }
  .inner > footer {
    background: #fff;
    border-top: solid 1px #e4e4e4;
    display: flex;
    flex-direction: row-reverse;
    padding: 15px;
    text-align: right;
    z-index: 1001;
    @include for-size(tablet-landscape-up) {
      padding: 30px 60px;
    }
    > button {
      border: 2px solid transparent !important;
      border-radius: 50%;
      font-size: 1rem;
      height: 50px;
      margin-left: 15px;
      min-width: auto;
      width: 50px;
      vertical-align: bottom;
    }
  }
  .right-arrow {
    position: absolute;
    width: 9px;
    height: 18px;
    right: 6px;
    top: 0;
    background: url("~/../src/assets/arrow.svg") 50% 50% no-repeat;
    background-size: contain;
    opacity: 0.7;
    pointer-events: none;
  }
  .right-0 {
    right: 0px;
  }
  &__Subheader {
    line-height: 18px;
    @include for-size(tablet-landscape-up) {
      // margin-left: 70px;
      .number {
        margin-left: 1em;
      }
      > div {
        display: inline-block;
      }
    }
    span {
      color: #7a7a7a;
      font: 15px/18px $baseFont;
    }
    .dash {
      color: #000;
    }
    .number__wrapper {
      position: relative;
      line-height: 8px;
      margin-right: 6px;
    }
    .number {
      & + select {
        background: transparent;
        border: 0;
        font: 15px/18px $baseFont;
        margin-left: 0.4em;
        -moz-appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
        z-index: 100;
        width: auto;
        padding: 0px 10px 0px 0px;
      }
    }
    .datetimeWrapper {
      display: inline-block;
      z-index: 100;
      .icon-date {
        z-index: 100;
        margin-right: 6px;
        > input {
          padding-right: 10px;
          box-sizing: border-box;
        }
      }
    }
    .rdt {
      line-height: 0px;
      &:before {
        display: none;
      }
      .form-control {
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        text-align: center;
        width: 90px;
      }
    }
  }
  &__MostTexted {
    .flow_wrapper {
      color: #bababa;
      margin-top: 15px;
      font: 600 13px/19px "Helvetica Neue", Helvetica, Arial, sans-serif;
      float: right;
      position: relative;
    }
    #flow {
      color: #242424;
      font: 400 13px/16px $baseFont;
      cursor: pointer;
      border: 0;
      background: transparent;
      padding-right: 18px;
      text-align: right;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
    > section {
      height: 150px;
      padding: 20px 30px;
      > ol {
        color: #707070;
        font: 400 15px/30px $baseFont;
        list-style: none;
        margin: 0;
        padding: 0;
        > li {
          display: flex;
          flex-direction: row;
          > i {
            margin-right: 0.5em;
            line-height: 30px;
          }
          > span {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          position: relative;
        }
        b {
          margin-left: 0.5em;
        }
        b,
        span {
          color: #242424;
        }
      }
    }
    .no-data {
      display: block;
      margin: 10px 0;
    }
  }
  &__Report {
    background: #fff;
    border: solid 2px #dfdfdf;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 20px 40px;
    position: relative;
    width: 140px;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:hover {
      border-color: #00bbe5;
      > span {
        color: #00bbe5;
      }
    }
    > i {
      color: #bdbdbd;
      font-size: 1.7em;
    }
    > span {
      bottom: -26px;
      color: #707070;
      font: bold 13px/16px $baseFont;
      display: block;
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
    }
  }
  &__MessagesBreakdown {
    .report {
      position: relative;
    }
    .legend {
      color: #242424;
      float: right;
      font: 400 14px/50px $baseFont;
      .right-arrow {
        position: absolute;
        width: 9px;
        height: 18px;
        right: -3px;
        top: 17px;
        background: url("~/../src/assets/arrow.svg") 50% 50% no-repeat;
        background-size: contain;
        opacity: 0.7;
        pointer-events: none;
      }
      > .inbound::before,
      > .outbound::before {
        border-radius: 50%;
        content: "";
        height: 11px;
        margin-right: 6px;
        width: 11px;
        display: inline-block;
      }
      > .inbound {
        &::before {
          background: #004b87;
        }
      }
      > .outbound::before {
        margin-left: 30px;
        background: #ed8b00;
      }
      .blast-dropdown {
        border: 0;
        -webkit-appearance: none;
        padding: 10px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    > header {
      .legend {
        visibility: hidden;
        @include for-size(tablet-landscape-up) {
          visibility: visible;
        }
      }
      .header-item {
        display: flex;
        align-items: center;
        gap: 5px;
        .icon-download {
          cursor: pointer;
        }
      }
    }
    > section {
      height: 100%;
      max-height: 272px;
      overflow-y: auto;
      > div {
        border-left: solid 1px #dfdfdf;
        margin: 0 74px 0 74px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        &.test {
          border: 0;
          margin: 0;
          padding: 15px 35px 15px 15px;
          .blast-text {
            color: #707070;
            font: 13px/30px "Helvetica Neue", Helvetica, Arial, sans-serif;
            line-height: 1.2;
            margin-bottom: 10px;
            span {
              display: block;
            }
          }
        }
        &.no-data {
          border-color: transparent !important;
          box-sizing: border-box;
          justify-content: center !important;
          padding: 30px 20px;
          margin: 0;
        }
        .legend {
          display: block;
          @include for-size(tablet-landscape-up) {
            display: none;
          }
        }
      }
    }
  }
  .no-data {
    color: #7a7a7a;
    font: 400 14px/17px $baseFont;
    text-align: center;
  }
  &__MessagesActivity {
    > section {
      padding: 15px 20px 30px;
      .column {
        display: inline-block;
        text-align: center;
        width: 50%;
        > h5 {
          color: #7a7a7a;
          font: 400 14px/17px $baseFont;
          margin: 15px 0 0 0;
        }
        > span {
          color: #242424;
          font: 300 36px/44px $baseFont;
        }
      }
      .no-data {
        margin-top: 15px;
        display: block;
      }
      .success {
        color: #2fcd07 !important;
      }
      .failed {
        color: #ef4902 !important;
      }
    }
  }
  &__ReportDetails {
    display: flex;
    flex-direction: column;
    @include for-size(tablet-landscape-up) {
      flex-direction: row;
      > div:first-child {
        width: 65%;
      }
      > div {
        width: 35%;
      }
    }
  }
  &__Panel {
    background: #fff;
    border-top: solid 1px #dfdfdf;
    &:last-child {
      border-bottom: solid 1px #dfdfdf;
    }
    @include for-size(tablet-landscape-up) {
      border: solid 1px #dfdfdf;
      margin: 10px 5px;
    }
    > header {
      border-bottom: solid 1px #dfdfdf;
      font: bold 14px/50px $baseFont;
      padding: 0 30px;
    }
  }
  &__Progress {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    min-width: 50px;
    &:first-child {
      padding-top: 20px;
      > .ReportsModal__ProgressInbound > span {
        margin-top: -10px;
        padding-top: 10px;
      }
    }
    &:last-child {
      padding-bottom: 20px;
      > .ReportsModal__ProgressInbound > span {
        margin-bottom: -10px;
        padding-bottom: 10px;
      }
    }
  }
  &__ProgressInbound {
    position: relative;
    background: #004b87;
    height: 10px;
    > span {
      position: absolute;
      right: 100%;
      top: -10px;
      color: #707070;
      font: 13px/30px $baseFont;
      padding: 0 20px;
      margin-right: 20px;
    }
  }
  &__ProgressOutbound {
    position: relative;
    background: #ed8b00;
    height: 10px;
    > span {
      position: absolute;
      line-height: 30px;
      left: 100%;
      top: -10px;
      color: #000;
      font: bold 13px/30px $baseFont;
      margin-left: 20px;
    }
  }
  .refresh {
    margin-left: 20px;
    button {
      color: #fff !important;
      font-size: 16px !important;
      background-color: rgb(60, 133, 68);
      display: grid;
      grid-template-columns: 80px 0;
      align-items: center;
      padding-left: 10px;
    }
    img {
      height: 24px;
    }
  }
}

.failed-help {
  position: relative;
}

.help-icon-abs {
  position: absolute;
  top: -10px;
  margin-left: 0 !important;
  right: 15px;
}

.tooltipContainer {
  padding-top: 16px;
  padding-bottom: 16px;
  width: 300px;
  @include for-size(phone-only) {
    width: 272px;
    &:after {
      left: 36%;
    }
  }
}

.tooltipContent {
  width: 300px;
  line-height: 1.25;
  text-transform: initial;
  ul {
    padding-left: 8px;
    list-style: none;
    i {
      color: #fff;
      margin-right: 4px;
    }
    i.icon-phone {
      filter: invert(100%);
    }
  }
}

.header-text {
  font-weight: bold;
}

.campaign {
  border-bottom: none !important;
  .download {
    display: grid;
    grid-template-columns: 0fr 0fr 0fr;
    align-items: center;
    height: 50px;
  }
  .icon-download {
    cursor: pointer;
  }
  .message-dropdown {
    border: 0;
    -webkit-appearance: none;
    padding: 10px;
    margin-left: 5px;
    cursor: pointer;
  }
  .right-arrow1 {
    width: 9px;
    height: 18px;
    background: url("~/../src/assets/arrow.svg") 50% 50% no-repeat;
    background-size: contain;
    opacity: 0.7;
    pointer-events: none;
  }
}

.campaign-help {
  position: relative;
  .help-icon-abs {
    top: 10px !important;
    right: -18px !important;
    height: 14px;
  }
}

.header-text {
  margin-left: 25px;
}

.campaign-tp {
  width: 300px;
  line-height: 22px !important;
  span {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.lt-align {
  text-align: left;
}

.ml0 {
  margin: 10px 0;
  display: block;
  text-align: center;
  font-size: 16px;
}

.rt-section {
  min-height: 232px;
  overflow-y: auto;
}

.wd15 {
  width: 15px;
}
