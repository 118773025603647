@import "../../mixins.scss";

html,
body,
#root {
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: none;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.powered {
  margin: 0 auto;
  width: 200px;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.error-msg {
  color: #dd3b26;
  font: 13px $baseFont;
  display: block;
  margin: 10px 0;
}

input[type="text"],
input[type="tel"],
input[type="number"],
textarea {
  background: #f5f5f5;
  border: 2px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  font: 15px/18px $baseFont;
  margin-bottom: 20px;
  padding: 16px 20px;
  width: 100%;
  &:focus {
    background-color: white;
    border-color: $brandColor;
  }
  &.error {
    border: solid 2px #dd3b26;
  }
  &:focus:required:invalid {
    border: 2px solid #dd3b26;
  }
}
textarea {
  max-width: 100%;
  min-width: 100%;
  &.remove-margin-bottom {
    margin-bottom: 0px !important;
  }
}
input[type="tel"] {
  padding-left: 80px;
}

.react-phone-number-input__row {
  position: relative;
  > input {
    width: 100%;
  }
}
.react-phone-number-input__icon > * {
  border-radius: 3px;
  display: block;
  height: 34px;
  min-width: 45px;
}
.react-phone-number-input__country {
  position: absolute;
  margin-top: -27px;
  top: 50%;
  left: 10px;
  &-select-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #757575 transparent transparent transparent;
    position: absolute;
    top: 50%;
    left: 120%;
    transform: translateY(-50%);
  }
}

.react-phone-number-input__country-select {
  -webkit-appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border: 0;
  border-radius: 0;
  opacity: 0;
}

.help-icon {
  vertical-align: bottom;
  height: 16px;
  margin-left: 0.5em;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  &:hover {
    opacity: 0.9;
    font-weight: 500;
  }
}
.mandatory::after {
  color: #dd3b26;
  content: "*";
  font-weight: 800;
  font-size: 18px;
  margin-left: 0.3em;
}

.select {
  > span {
    display: none !important;
  }
  > span + div {
    background-color: white !important;
    box-shadow: none !important;
    outline: none !important;
    input[type="text"] {
      margin: 10px 0 !important;
    }
  }
  > div {
    background: #f5f5f5 !important;
    border-width: 2px !important;
    border-color: transparent;
    padding: 0px 10px !important;
    font: 15px/18px $baseFont !important;
    border-radius: 3px !important;
    &:hover {
      border-color: transparent;
    }
    &:last-child {
      padding: 0 !important;
    }
  }
}

.tooltip {
  background: #4b4b4b;
  border-radius: 3px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  color: #fff;
  // display: none;
  opacity: 0;
  transition: opacity 0.3s ease, margin 0.3s ease;
  font: 13px $baseFont;
  left: 100%;
  margin-left: 30px;
  position: absolute;
  padding: 14px 20px;
  top: 50%;
  transform: translateY(-50%) scale(0);
  z-index: 10;
  line-height: 100%;
  min-width: 100px;
  &::after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-right-color: #4b4b4b;
    border-width: 5px;
    margin-top: -5px;
  }
}

@font-face {
  font-family: "icomoon";
  src: local("icomoonRegular"),
    url("../../assets/fonts/icomoon.woff") format("woff"),
    url("../../assets/fonts/icomoon.ttf") format("truetype"),
    url("../../assets/fonts/icomoon.eot"),
    url("../../assets/fonts/icomoon.eot#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-online:before {
  background: #2fcd07;
}
.icon-offline:before {
  background: #ccc !important;
}
.icon-green:before {
  background: #2fcd07 !important;
}
.icon-yellow:before {
  background: #ffa300 !important;
}
.icon-red:before {
  background: #ef4902 !important;
}
.icon-gray:before {
  background: #aaa !important;
}
.icon-circle:before {
  background: $brandColor;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
}

.icon-phone:before {
  background-image: url(../../assets/phone.svg);
  display: inline-block;
  height: 12px;
  width: 12px;
  content: "";
}
.text-center {
  text-align: center;
}

.icon-add:before {
  content: "\e900";
}
.icon-advanced-search:before {
  content: "\e901";
}
.icon-attach:before {
  content: "\e902";
}
.icon-back:before {
  content: "\e903";
}
.icon-broadcast:before {
  content: "\e904" !important;
}
.icon-chat:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e906";
}
.icon-contacts:before {
  content: "\e907";
  font-size: 0.8em;
}
.icon-date:before {
  content: "\e908";
}
.icon-delete:before {
  content: "\e909";
}
.icon-edit:before {
  content: "\e90a";
}
.icon-emoji:before {
  content: "\e90b";
}
.icon-eye:before {
  content: "\e90c";
}
.icon-flash:before {
  content: "\e90d";
}
.icon-group:before {
  content: "\e90e";
}
.icon-help:before {
  content: "\e90f";
}
.icon-hide:before {
  content: "\e910";
}
.icon-import:before {
  content: "\e911";
}
.icon-inbound:before {
  content: "\e912";
}
.icon-info:before {
  content: "\e913";
}
.icon-location:before {
  content: "\e914";
}
.icon-outbound:before {
  content: "\e915";
}
.icon-plus:before {
  content: "\e916";
}
.icon-profile:before {
  content: "\e917";
}
.icon-reply-options:before {
  content: "\e918";
  font-size: 0.7315em;
}
.icon-reports:before {
  content: "\e919";
}
.icon-search:before {
  content: "\e91a";
}
.icon-send:before {
  content: "\e91b";
}
.icon-settings:before {
  content: "\e91c";
}
.icon-download:before {
  content: url("../../assets/downlaod.svg");
}
.icon-vertical-dotted::before {
  content: url("../../assets/vertical-dotted.svg");
}
.icon-clock:before {
  content: url("../../assets/clock-icon8.svg");
}
.time-icon-clock:before {
  content: url("../../assets/clock-icon5.svg");
}
// ::-webkit-scrollbar-track {
//   background: hsl(205deg 75% 18% / 10%);
// }
// ::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.2);
// }
// ::-webkit-scrollbar {
//   width: 6px !important;
//   height: 6px !important;
// }
