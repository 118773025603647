@import "../../../mixins.scss";

.TemplateEdit {
  display: none;
  overflow: inherit;
  &__toggle {
    border-top: solid 1px #e4e4e4;
    padding: 20px 30px;
    > div {
      margin-right: 30px;
    }
    > span {
      color: #686868;
      font: 14px/32px $baseFont;
    }
  }

  @include for-size(tablet-landscape-up) {
    background: #f6f6f6 !important;
    display: block;
    min-width: 600px;

    > header {
      button,
      h1 {
        display: none !important;
      }
    }

    &__Form {
      // overflow: inherit !important;
      min-height: 91%;
      background: #fff;
      border: 1px solid #e4e4e4;
      margin: 30px 60px;
    }

    > div:first-of-type {
      margin-top: 0 !important;
    }

    > footer {
      padding: 30px 60px !important;
      position: relative !important;
    }
  }

  &.shown {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  button > i {
    display: block;
  }

  > header {
    padding-bottom: 0 !important;
    button {
      color: #4c4c4c !important;
      height: 40px !important;
      font-size: 18px !important;
      line-height: 40px !important;
      margin-left: -15px;
      width: 40px !important;
    }

    h1 {
      color: #242424;
      font: 300 30px/40px $baseFont;
      margin: 8px 0 0;
    }
  }

  &__Form {
    flex-grow: 1;
    overflow: auto;
    padding-top: 30px;
    > div {
      padding: 0 30px;
    }
  }

  #keywords > div {
    background: #f5f5f5;
    border: 2px solid transparent;
    border-radius: 3px;
    box-shadow: none !important;
    box-sizing: border-box;
    font: 15px/18px "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
    padding: 7px 10px;
    width: 100%;
    min-height: 54px;

    @include for-size(phone-only) {
      line-height: 15px;
      font-size: 14px;
    }

    > div {
      padding: 0 8px;
    }

    input[type="text"] {
      margin-bottom: 0;
    }
  }

  > .panel {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    &.open {
      display: block;
    }

    @include for-size(tablet-landscape-up) {
      background: transparent;
      position: absolute;
      left: -30px;
      right: -30px;
      bottom: -30px;
      > div {
        border-radius: 4px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
        width: 300px;
        left: auto !important;
        top: auto;
        right: 30px !important;
        bottom: 30px !important;
        > button.close {
          display: none;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      > button {
        margin: 30px;
        margin-top: 15px;
        margin-bottom: 30px;
        border: 2px solid #e3e3e3;
        line-height: 50px;
        border-radius: 50px;
        font-size: 16px;
        color: #dd3b26;
        &:first-child {
          margin-top: 30px;
          margin-bottom: 0;
        }
        &.is-primary {
          color: #fff;
          border-color: transparent;
        }
        &.close {
          border: 0;
          border-top: 1px solid #dbdbdb;
          border-radius: 0;
          color: $brandColor;
          margin: 0;
        }
      }
    }
  }

  > header {
    padding: 30px;
    @include for-size(tablet-landscape-up) {
      display: none;
    }
  }

  > footer {
    padding: 15px 30px;
    border-top: solid 1px #dfdfdf;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 50px;

    @include for-size(tablet-landscape-up) {
      display: block;
      > button:first-child {
        margin-right: 30px !important;
      }
    }

    > button {
      background: none;
      border-radius: 25px;
      color: #9e9e9e;
      cursor: pointer;
      font: 500 16px $baseFont;
      height: 50px;
      width: 50%;
      @include for-size(tablet-landscape-up) {
        max-width: 100px;
        &.is-primary {
          max-width: 200px;
        }
      }
      &.is-primary {
        margin-right: 0;
      }
      &.more {
        width: 50px;
        min-width: 50px;
        float: right;
        border: 2px solid #e3e3e3;
        color: $brandColor;
      }
    }
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    label {
      flex-grow: 1;
      width: 100%;
    }
    > div {
      flex-grow: 1;
      @include for-size(tablet-landscape-up) {
        &:first-child {
          margin-right: 15px;
        }
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
}

.rdt.icon-date {
  > input {
    padding-left: 48px;
  }
  &::before {
    color: #555;
    left: 20px;
    position: absolute;
    top: 18px;
  }
}

.high-light {
  width: 100%;
  .styles_highlights__3FFG4 {
    padding: 16px 0px 16px 16px;
    font: 15px/18px $baseFont;
    mark {
      border-radius: 3px;
      color: transparent;
      background-color: #b1d5e5;
      // margin-left: 1px;
    }
  }
  .styles_backdrop__2g-i9 {
    width: 100%;
  }
}
// .styles_backdrop__2g-i9::-webkit-scrollbar {
//   width: 18px;
//   background-color: #f5f5f5;
// }
// .styles_backdrop__2g-i9::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #c1c1c1;
// }

.temp {
  cursor: pointer;
  text-decoration: none;
}

.template-btn {
  display: inline-flex;
  position: relative;
  line-height: normal;
}
