@import "../../../mixins.scss";

.TemplateModal {
  .tooltipContainer {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 300px;

    @include for-size(phone-only) {
      width: 272px;
      &:after {
        left: 36%;
      }
    }
  }

  .tooltipRight {
    @include for-size(phone-only) {
      &:after {
        left: 90%;
      }
    }
  }

  .tooltipContent {
    width: 300px;
    line-height: 1.25;
    text-transform: initial;

    ul {
      padding-left: 8px;
      list-style: none;
      i {
        color: #fff;
        margin-right: 4px;
      }
      i.icon-phone {
        filter: invert(100%);
      }
    }
  }

  .inner > section {
    background: #f6f6f6;
    flex-direction: column;
    max-height: calc(100% - 208px);
    @include for-size(tablet-landscape-up) {
      position: relative;
      max-height: calc(100% - 238px);
    }
    .SearchInput {
      margin: 20px 30px;
      @include for-size(tablet-landscape-up) {
        margin: 30px 60px 0;
      }
    }
  }
  .inner > header {
    padding: 30px 30px 0;
    @include for-size(tablet-landscape-up) {
      padding: 30px 60px 0;
    }
  }
  .inner > ul {
    margin: 0;
  }
  .inner > footer {
    background: #fff;
    border-top: solid 1px #e4e4e4;
    display: flex;
    flex-direction: row-reverse;
    padding: 15px;
    text-align: right;
    z-index: 1001;

    @include for-size(tablet-landscape-up) {
      padding: 30px 60px;
    }

    > button {
      border: 2px solid transparent !important;
      border-radius: 50%;
      font-size: 1rem;
      height: 50px;
      margin-left: 15px;
      min-width: auto;
      width: 50px;
      vertical-align: bottom;
    }
  }
  .SearchInput {
    flex-grow: 1;
  }
}
