@import "../../../mixins.scss";
.ContactsModal {
  font-family: $baseFont;
  .errors {
    font-size: 14px;
    color: red;
  }
  .tooltipContainer {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 300px;

    @include for-size(phone-only) {
      width: 272px;
      &:after {
        left: 36%;
      }
    }
  }

  .tooltipRight {
    @include for-size(phone-only) {
      &:after {
        left: 66%;
      }
    }
  }

  .tooltipContent {
    width: 300px;
    line-height: 1.25;
    text-transform: initial;

    ul {
      padding-left: 8px;
      list-style: none;
      i {
        color: #fff;
        margin-right: 4px;
      }
      i.icon-phone {
        filter: invert(100%);
      }
    }
  }

  section {
    height: calc(100% - 250px);
    > div:first-child {
      width: 100%;
      @include for-size(tablet-landscape-up) {
        max-width: 400px;
      }
    }
  }

  &__Tabs > li:hover {
    .tooltip {
      // display: block;
      transform: translateY(-50%) scale(1);
      opacity: 1;
      margin-left: 20px;
    }
  }

  .inner {
    height: 100%;
  }

  &__SearchInput {
    margin: 10px 30px 10px 30px !important;
    width: calc(100% - 60px) !important;
  }

  .inner > footer {
    background: #fff;
    border-top: solid 1px #e4e4e4;
    padding: 15px 30px;
    text-align: right;
    z-index: 1001;

    > button {
      border: 2px solid transparent !important;
      border-radius: 50%;
      font-size: 1rem;
      height: 50px;
      margin-left: 15px;
      min-width: auto;
      width: 50px;
      vertical-align: bottom;
    }
  }
}
