@import "../../mixins.scss";

.ProfileStatus {
  cursor: pointer;
  margin: 0 26px;
  position: relative;
  user-select: none;

  > div {
    > button {
      background: url("../../assets/arrow.svg") no-repeat 100% 50%;
      color: rgba(36, 36, 36, 0.6);
      font: 400 14px/70px $baseFont;
      max-width: 150px;
      overflow: hidden;
      padding-right: 25px;
      text-overflow: ellipsis;
      white-space: nowrap;
      > i {
        margin-right: 10px;
      }
      &:hover {
        font-weight: 400;
      }
    }

    > div {
      background: #fff;
      position: absolute;
      border-radius: 4px;
      top: 100%;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
      font: 400 14px/17px $baseFont;
      right: 0;
      max-width: 300px;
      min-width: 300px;
      z-index: 1000;
      > ul {
        border-bottom: solid 1px #eaeaea;
        list-style: none;
        margin: 0;
        padding: 17px 0;
        > li {
          color: #242424;
          padding: 12px 74px 12px 22px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          &.editing {
            background: #fff !important;
            padding: 0 12px;
            input[type="text"].ThemedStatusInput {
              padding-right: 70px !important;
            }
          }
          > i {
            margin-right: 15px;
          }
          &:hover {
            background: #f8f8f8;

            > .buttons {
              visibility: visible !important;
            }
          }

          > .buttons {
            position: absolute;
            right: 22px;
            visibility: hidden;
            > button {
              width: 16px;
              height: 16px;
              font-size: 16px;
              color: #545454;
              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
        & + .ThemedStatusInput__wrapper {
          padding: 12px;
        }
      }
    }
  }
}
