@import "../../mixins.scss";

.Chat {
  --menu-hover-color: rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: column;
  flex-grow: 1;
  justify-content: stretch;
  width: 0;
  transition: all 0.3s ease-in-out;
  &.shown {
    display: flex;
  }
  @include for-size(tablet-landscape-up) {
    display: flex;
  }

  &__HeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
  }

  &__HeaderBack {
    @include for-size(tablet-landscape-up) {
      visibility: hidden;
    }
  }

  &__HeaderControls {
    right: 20px;
    @include for-size(tablet-landscape-up) {
      right: 40px;
    }
  }

  &__HeaderTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%;
    > h2,
    > div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }

    > h2 {
      color: #242424;
      font: 500 16px/20px $baseFont;
      margin: 0;
      @include for-size(tablet-landscape-up) {
        font-weight: 600;
        line-height: 21px;
      }
    }

    > div {
      color: #a1a1a1;
      margin-top: 4px;
      font: 14px $baseFont;
      @include for-size(tablet-landscape-up) {
        margin-top: 5px;
      }
    }
  }

  > header {
    background: #fafafa;
    border-bottom: solid 1px #e3e3e3;
    height: 70px;
    min-height: 70px;
    padding: 8px 20px;
    position: relative;
    text-align: center;

    @include for-size(tablet-landscape-up) {
      padding: 0px 30px;
    }

    @include for-size(phone-only) {
      height: 40px;
      min-height: 40px;
    }

    button {
      background: none;
      border: 0;
      color: #242424;
      font-size: 1.25em;
      margin-left: 20px;
      padding: 0;
      cursor: pointer;
      > i {
        display: block;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }

  > ul.messages {
    flex-grow: 1;
    list-style: none;
    overflow-y: auto;
    padding: 0 10%;
    margin: 0;
    height: 100%;

    li {
      padding: 10px 20px 10px 0;
      position: relative;
      button {
        position: absolute;
        right: -5px;
        top: 10px;
        pointer-events: none;
        opacity: 0;
      }
      &:hover {
        button {
          opacity: 1;
          pointer-events: all;
        }
      }
      > div {
        background: #00bbe5;
        border: 2px solid #00bbe5;
        border-radius: 0 14px 14px;
        box-sizing: border-box;
        color: #fff;
        display: inline-block;
        font: 16px/25px $baseFont;
        padding: 0.8em 1em;
        word-break: break-word;
        > div {
          &.bubble {
            text-align: left;

            > span {
              white-space: pre-wrap;
            }

            .media {
              display: flex;
              align-items: flex-start;
              margin-top: 8px;

              img {
                width: 200px;
                height: 200px;
                object-fit: cover;
                border-radius: 2px;
              }
            }
          }

          .preview {
            svg {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }

    li.me {
      text-align: right;
      > div {
        background: transparent;
        border-radius: 14px 0 14px 14px;
        color: #242424;
      }
    }

    li:not(.me) {
      .filePreview {
        background: #fff;
        color: #242424;
      }
      .imageDetails {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
      }
    }

    li.autoreply {
      // text-align: right;
      > div {
        background: #f5f5f5 !important;
        border-color: transparent !important;
        border-radius: 14px 0 14px 14px;
        color: #242424;
      }
    }

    li > small {
      color: rgba(161, 161, 161, 0.9);
      display: block;
      font: 13px $baseFont;
      margin-bottom: 5px;
    }
  }

  .filePreview {
    background: #242424;
    border-radius: 3px;
    color: #fff;
    display: block;
    object-fit: cover;
    text-align: center;
    text-transform: uppercase;
  }

  .imageDetails {
    align-items: center;
    background-color: #f2f2f2;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    box-sizing: border-box;
    color: #242424;
    display: flex;
    justify-content: space-between;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      > div {
        font: 15px/18px $baseFont;
        &:last-child {
          color: #787878;
          font: 13px/16px $baseFont;
          margin-top: 5px;
        }
      }
    }
  }

  .media {
    .flex {
      align-items: center;
    }
  }

  .media {
    > a {
      text-decoration: none;
    }
    .filePreview {
      font: bold 10px/40px $baseFont;
      height: 40px;
      width: 40px;
      &.generic {
        font-size: 16px;
      }
    }
    .imageDetails {
      font: 13px/34px $baseFont;
      height: 34px;
      max-width: 500px;
      padding: 0 15px;
      > div {
        width: 100%;
      }
    }
  }

  > footer {
    justify-content: center;
    color: #707070;
    font: 16px/70px $baseFont;
    align-items: center;
    align-self: center;
    border-top: solid 1px #e3e3e3;
    display: flex;
    // min-height: 70px;
    width: 100%;
    flex-direction: row;
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    z-index: 101;

    .badge {
      position: absolute;
      right: 6px;
      top: 0px;
      height: 12px;
      width: 12px;
      border-radius: 6px;
      font-size: 8px;
      background: rgb(0, 119, 200);
      color: white;
      font-weight: bold;
      padding-top: 1px;
      box-sizing: border-box;
    }

    .options {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 50px;

      > button {
        position: relative;
      }

      button.prebuiltRepliesBtn {
        position: relative;
        color: #a0a0a0;
        &.has-replies {
          color: $brandColor;
        }
        > span {
          position: absolute;
          top: 0;
          right: 0;
          background: #ef4902;
          width: 17px;
          color: #fff;
          height: 17px;
          border-radius: 50%;
          z-index: 5;
          font: 10px/17px $baseFont;
          text-align: center;
        }
      }

      button.templateBtn {
        position: relative;
        color: #a0a0a0;
        &.has-templates {
          color: $brandColor;
        }
        > span {
          position: absolute;
          top: 0;
          right: 0;
          background: #ef4902;
          width: 17px;
          color: #fff;
          height: 17px;
          border-radius: 50%;
          z-index: 5;
          font: 10px/17px $baseFont;
          text-align: center;
        }
      }

      &.shown {
        > button:first-child {
          display: none;
        }
        > button:not(:first-child) {
          display: block;
        }
      }
      > button:not(:first-child) {
        display: none;
      }

      @include for-size(tablet-landscape-up) {
        > button:first-child {
          display: none !important;
        }
        > button:not(:first-child) {
          display: block !important;
        }
      }
    }

    @include for-size(tablet-landscape-up) {
      padding: 0;
      width: 80%;

      > span {
        font-size: 14px;
        line-height: 40px;
        position: relative;
      }
    }

    > input[type="text"] {
      background: none;
      border-radius: 0;
      width: 100%;
      border: 0;
      border-left: solid 1px #e3e3e3;
      font-size: 1em;
      height: 38px;
      margin: 0 1.25em;
      padding: 0;
      padding-left: 1.25em;
      outline: none;
    }

    > span {
      background: rgba(255, 255, 255, 0.8);
      padding: 5px 10px;
      color: #c8c8c8;
      font: 12px/15px $baseFont;
      position: absolute;
      right: 0px;
      top: -26px;
    }

    > button[type="submit"],
    button[type="button"] {
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      font-size: 1.25em;
      outline: none;
      padding: 0;
    }

    button[type="button"] {
      background: none;
      color: $brandColor;
      height: 2em;
      margin: 0;
      width: 2em;
      min-width: 2em;
      padding: 0;
      font-size: 1.25em;
      &:hover {
        background: #fafafa;
      }
    }

    i.icon-send {
      letter-spacing: -5px;
      display: block;
    }

    > button[type="submit"] {
      background: $brandColor;
      color: #fff;
      height: 50px;
      width: 50px;
      margin: 0 0 0 1em;
      min-width: 50px;
      max-width: 50px;

      &.disabled {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
}
.text-message {
  width: 30%;
  margin: 13% 0;
}
.custom-menu {
  display: inline-block;
  z-index: 999;
  span {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

.menu-theme {
  > div:hover {
    background: var(--menu-hover-color) !important;
    opacity: 1;
  }
}

.textarea-control {
  background: none;
  border-radius: 0;
  width: 100%;
  border: 0;
  border-left: solid 1px #e3e3e3;
  font-size: 1em;
  height: 38px;
  margin: 0 1.25em;
  padding: 0;
  padding: 0.5em 0 0 1.25em;
  outline: none;
  resize: none;
  line-height: 1.4;
  min-width: auto;
  margin-bottom: 20px;
  margin-top: 20px;

  &:focus {
    border-left: solid 1px #e3e3e3;
  }
}

.scheduler-wrapper {
  display: flex;
  flex-grow: 1;
  .scheduler-window {
    width: 0px;
    background: #fff;
    border-left: 1px solid #d7d7d7;
    right: 0;
    transition: all 0.3s ease-in-out;
    transform: translateX(100%);
    overflow: hidden;
    backface-visibility: hidden;
  }
  &.open {
    .scheduler-window {
      transform: translateX(0);
      width: 365px;
    }
  }
}
.schedule-icon {
  position: relative;
  top: 4px;
  right: 4px;
}

.chat-header-schedule-panel {
  padding: 8px;
  cursor: pointer;
  height: 25px;
  &.open {
    background: #e8f0fe;
    border-radius: 50%;
  }
}

.bubble {
  a {
    margin-left: 0px !important;
  }
}

.disable_footer {
  pointer-events: none !important;
}
