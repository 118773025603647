@import "../../../mixins.scss";

.HelpInfoModal {
}
.HelpInfoModalContent {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  font-family: $baseFont;
  justify-content: stretch;
  overflow-y: hidden;
  &__Sidebar {
    border-right: 1px solid #dfdfdf;
  }
  &__Sidebar {
    display: flex;
    width: 325px;
    margin-top: -120px;
    padding-top: 120px;

    @include for-size(phone-only) {
      width: 100%;
    }

    &__Items {
      flex-grow: 1;
      overflow-y: auto;
    }
    .level-1,
    .level-2 {
      cursor: pointer;
    }
    .level-1 {
      &.active,
      &:hover {
        background-color: #f2f2f2;
      }
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      color: #242424;
      padding: 10px 0 10px 60px;
    }
    .level-2 {
      padding: 7px 0 7px 15px;
      margin-left: 60px;
      font-size: 14px;
      line-height: normal;
      color: #828282;
      position: relative;
      &:before {
        position: absolute;
        left: 0;
        top: 45%;
        transform: translateY(-50%);
        display: block;
        content: "";
        background-color: $brandColor;
        width: 4px;
        height: 4px;
        border-radius: 2px;
      }
    }
  }

  &__Footer {
    border-top: solid 1px #dfdfdf;
    color: #828282;
    padding: 20px 40px;
    font: 14px/17px $baseFont;
    overflow-wrap: break-word;
    > b {
      color: #242424;
      margin-right: 15px;
    }
    > span {
      margin: 0 15px;
      display: inline-block;
    }
    > a {
      color: #00bbe5;
    }
  }

  &__Content {
    padding: 0 60px;
    flex: 6;
    font-size: 16px;
    color: #707070;
    line-height: 23px;
    overflow-y: auto;

    @include for-size(phone-only) {
      background-color: #fff;
      width: 100%;
      position: absolute;
      left: 100%;
      box-sizing: border-box;
      overflow-y: scroll;
      height: calc(100% - 100px);
      padding-bottom: 20px;

      &.selected {
        left: 0;
      }

      .backButton {
        display: block !important;

        .icon-back {
          font-size: 16px;
        }
      }
    }

    .backButton {
      display: none;
    }

    img {
      margin: 0;
      width: 100%;
    }

    ul.faq {
      list-style: none;
      padding: 0;
      li {
        strong {
          cursor: pointer;
          color: #242424;
        }
        &:before {
          content: "\25B8";
          padding-right: 0.5em;
          margin-left: -1em;
        }
        &.active:before {
          content: "\25BE";
        }
      }
    }
  }
}
