.ChatView {
  display: flex;
  flex-direction: column;
  height: 100%;

  > .content {
    display: flex;
    height: calc(100% - 70px);
    &:last-child {
      flex-grow: 1;
    }
  }
  > .menu_open {
    .Chat {
      ul.messages {
        overflow-y: hidden;
        width: calc(80% - 18.89px);
      }
    }
  }
}

.copied_to_clipboard {
  position: absolute;
  top: -80px;
  left: calc(60% - 90px);
  width: 180px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
  color: white;
  z-index: 99999;
  padding-top: 7px;
  transition: all 0.1s ease-in-out;
  &.show {
    transition: all 0.1s ease-in-out;
    top: 15px;
  }
}
