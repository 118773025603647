.ThemedStatusInput__wrapper {
  position: relative;
  > button {
    border-right: solid 1px #e1e1e1;
    cursor: pointer;
    height: 20px;
    line-height: 21px;
    margin: 10px;
    padding: 0 10px 0 0;
    position: absolute;
    width: 20px;
    z-index: 4;
  }
}

input[type=text].ThemedStatusInput {
  margin: 0;
  width: 100%;
  padding: 9px 10px 9px 40px;
  position: relative;

  &.error {
    border-color: #DD3B26;
  }
  & + sub {
    color: #DD3B26;
    display: block;
    transform: translateY(-8px);
    transition: all 0.5s ease;
    opacity: 0;
    &.error {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.ThemedStatusInput__button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
}

.editButtons {
  position: absolute;
  top: 0;
  right: 0;
  > button {
    cursor: pointer;
    height: 20px;
    margin: 10px 10px 10px 0;
    vertical-align: top;
    width: 20px;
  }
}
