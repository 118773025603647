@import '../../../mixins.scss';

.datetimeWrapper {
  position: relative;
}
.rdt {
  position: relative;
}
.rdtPicker {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  display: none;
  font-family: $baseFont;
  font-size: 14px;
  position: absolute;
  z-index: 99999 !important;
  button {
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
  table {
    border-spacing: 5px;
    margin: 0;
    width: 100%;
  }
  thead {
    button {
      width: 100%;
      height: 100%;
    }
    tr:first-child th {
      color: $brandColorLighten;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 50px;
      &:hover {
        background: #eeeeee;
      }
    }
  }
  td,
  th {
    border: 2px solid transparent;
    border-radius: 3px;
    height: 26px;
    min-width: 26px;
    padding: 0;
    text-align: center;
  }
  td {
    cursor: pointer;
    &.rdtActive.rdtToday:before {
      border-bottom-color: #fff;
    }
    &.rdtActive,
    &.rdtActive:hover {
      background-color: $brandColorLighten !important;
      color: #fff !important;
    }
    &.rdtDisabled,
    &.rdtDisabled:hover {
      background: none;
      color: #999999;
      cursor: not-allowed;
    }
    &.rdtNew,
    &.rdtOld {
      color: #bdbdbd;
    }
    &.rdtToday {
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid $brandColorLighten;
        border-top-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
      }
    }
    &.rdtMonth,
    &.rdtYear {
      height: 50px;
      width: 25%;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
    span.rdtOld {
      color: #999999;
    }
    span.rdtDisabled,
    span.rdtDisabled:hover {
      background: none;
      color: #999999;
      cursor: not-allowed;
    }
  }
  td.rdtDay:hover,
  td.rdtHour:hover,
  td.rdtMinute:hover,
  td.rdtSecond:hover,
  .rdtTimeToggle:hover {
    border-color: $brandColorLighten;
    cursor: pointer;
  }
  th {
    &.rdtDisabled,
    &.rdtDisabled:hover {
      background: none;
      color: #999999;
      cursor: not-allowed;
    }
    &.rdtSwitch {
      width: 100px;
    }
    &.rdtNext,
    &.rdtPrev {
      font-size: 21px;
      vertical-align: top;
      width: 30px;
      height: 30px;
      line-height: 30px;
      &:before {
        display: block;
        font-family: 'icomoon';
      }
      span {
        display: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -khtml-user-select: none;    /* Konqueror */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;
      }
    }
    &.rdtNext:before {
      content: "";
      transform: rotate(180deg);
    }
    &.rdtPrev:before {
      content: "";
    }
  }
  .dow {
    width: 14.2857%;
    border-bottom: none;
    color: #242424;
    cursor: default;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .rdtTimeToggle {
    color: $brandColorLighten;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
  }
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}
.rdtCounters {
  display: inline-block;
  > div {
    float: left;
  }
}
.rdtCounter {
  height: 100px;
  width: 40px;
  .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
    &:hover {
      background: #eee;
    }
  }
  .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
}
.rdtCounterSeparator {
  line-height: 100px;
}
.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
  input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
}
.rdtTime td {
  cursor: default;
}
