input[type="checkbox"] {
  height: 0;
  width: 0;
  display: none !important;
}
input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  margin: 0 0 20px;
  align-items: center;
  color: #686868;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  line-height: 17px;
}
input[type="checkbox"] + label > span {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  float: left;
  background: transparent;
  border: 2px solid #dfdfdf;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  position: relative;
  top: 10px;
}

input[type="checkbox"] + label:hover > span,
input[type="checkbox"]:focus + label > span {
  background: rgba(255, 255, 255, 0.1);
}
input[type="checkbox"]:checked + label > span {
  background: #00bbe5;
  border-color: #00bbe5;
  position: relative;
}
input[type="checkbox"]:checked + label > span:before {
  content: "";
  position: absolute;
  top: 7px;
  right: 3px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #fff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #fff;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
.top {
  top: -8px;
}
