.SearchInput {
  display: block;
  position: relative;
  max-height: 50px;

  & > i {
    color: #a7a7a7;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8px;
  }

  & > button.StartConversation {
    border-left: solid 1px #e3e3e3;
    color: #a7a7a7;
    font-size: 15px;
    height: 43px;
    position: absolute;
    right: 0;
    top: 0;
    width: 43px;
    :hover,
    :focus {
      color: #242424;
      cursor: pointer;
    }
  }

  &.underline {
    border: 0;
    border-bottom: solid 1px #dadada;
    border-radius: 0;
    > input {
      border: 0;
      padding: 16px 30px;
      &:focus {
        background: transparent;
      }
    }
  }

  &.left-icon > i {
    left: 0;
    right: auto;
  }

  & > .suggestions {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    display: none;
    list-style: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    margin: 0 0 8px;
    padding: 0;
    &.open {
      display: block;
    }
  }

  & > input {
    color: #242424;
    height: 50px;
    margin: 0 !important;
  }
  &.search-button {
    & > i.icon-search {
      cursor: pointer;
      color: #fff;
      left: 3px !important;
      width: 45px;
      height: 90%;
      top: 2px;
      margin: 0px;
      text-align: center;
      line-height: 42px;
      border-radius: 4px 0 0 4px;
      font-size: 18px;
    }
  }
}
