$baseFont: 'Helvetica Neue', Arial, Helvetica, sans-serif;
.PhonesDropdown {
  cursor: pointer;
  position: relative;
  font: 300 15px/22px $baseFont;
  height: 100%;
  display: flex;
  align-items: center;

  &__PhoneItem {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    color: #242424;
    padding: 0 30px;
    width: 100%;
    box-sizing: border-box;

    &--list:not(:last-child) {
      margin-bottom: 20px;
    }

    &:hover {
      background: #f8f8f8;
    }
    > span {
      font-size: 14px;
    }

    > i.icon-back {
      transform: rotate(-90deg);
      font-size: 13px;
      bottom: 50%;
    }

    &__Label { 
      display: flex; 
      align-items: center; 

      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 2px;
        background-color: #000;
        margin-right: 15px; 

        img {
          width: 16px; 
          height: 16px; 
          filter: brightness(0) invert(1);
        }
      }

      > div:last-child {
        display: flex; 
        flex-direction: column;
      }
    }

    &__Badge { 
      width: 26px; 
      height: 26px; 
      border-radius: 13px; 
      display: flex; 
      justify-content: center; 
      align-items: center; 
      background-color: #EF4902;

      > span { 
        color: #fff; 
        font-size: 11px; 
        font-weight: bold; 
      }
    }
  }

  &__List {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgba(0,0,0,.15);
    display: none;
    list-style: none;
    padding: 24px 0;
    position: absolute;
    top: 100%;
    z-index: 100;
    width: 100%;

    > li {
      font: 300 15px/22px $baseFont;
      padding: 0 30px;
      &:hover {
        background: #f8f8f8;
      }
      > span {
        font-size: 14px;
      }
    }
  }

  &__List--header {
    min-width: 300px;
    left: -250px;
    top: 76%;
  }

  &.open > ul {
    display: block;
  }
}