@import '../../../mixins.scss';

.SearchForm {
  > footer {
    > button {
      @include for-size(tablet-landscape-up) {
        max-width: 100px;
        &.is-primary {
          max-width: 200px;
        }
      }
    }
  }
}
#conversations > div:first-child,
#conversations > span + div,
#period > span + div {
  padding: 0 !important;
}

#conversations input {
  height: 38px !important;
  line-height: 38px !important;
  margin-bottom: 0px !important;
}