@import '../../../mixins.scss';

.TimePicker {
  color: #242424;
  user-select: none;
  &.disabled {
    opacity: .3;
  }
  > input[type="text"] {
    width: 56px;
    text-align: center;
    margin: 0 7px;
    padding: 9px 0;
    &:first-child {
      margin-left: 0 !important;
    }
  }
  &__AmPm {
    display: inline-block;
    font: 14px/40px $baseFont;
    position: relative;
    text-align: center;
    width: 30px;
    > button {
      position: absolute;
      display: block;
      width: 100%;
      text-align: center;
      height: 50%;
      > i {
        color:rgba(36, 36, 36, 0.3);
        position: absolute;
        width: 100%;
      }
      &:first-child {
        top: 0;
        > i {
          top: 0;
          transform: rotateZ(90deg);
        }
      }
      &:last-child {
        bottom: 0;
        > i {
          bottom: 0;
          left: 1px;
          transform: rotateZ(-90deg);
        }
      }
    }
  }
}