@import "../../../mixins.scss";

.ScheduleEdit {
  display: none;
  // --calender-hover-color: rgba(0, 0, 0, 0.2);
  &__Scheduling > label {
    display: inline-block;
    &:not(:first-child) {
      margin-left: 50px !important;

      @include for-size(phone-only) {
        margin-left: 20px !important;
      }
    }
  }

  &__SchedulingTable {
    width: 100%;
    color: #242424;
    font: 500 13px/16px $baseFont;
    text-align: left;
    border-collapse: collapse;
    th {
      padding: 10px 0;
      // width: 33%;
    }
    .th_33,
    .td_33 {
      width: 33%;
    }
    .th_20,
    .td_20 {
      width: 20%;
    }
    .th_40,
    .td_40 {
      width: 40%;
    }
    .padding_left_15 {
      padding-left: 15px;
    }
    input[type="checkbox"] + label {
      line-height: 40px !important;
      margin-bottom: 0 !important;
      max-width: fit-content !important;
    }
  }

  &__toggle {
    border-top: solid 1px #e4e4e4;
    padding: 20px 30px;
    > div {
      margin-right: 30px;
    }
    > span {
      color: #686868;
      font: 14px/32px $baseFont;
    }
  }

  @include for-size(tablet-landscape-up) {
    background: #f6f6f6 !important;
    display: block;
    min-width: 600px;
    position: relative !important;

    > header {
      button,
      h1 {
        display: none !important;
      }
    }

    > form {
      background: #fff;
      border: 1px solid #e4e4e4;
      margin: 30px 60px;
    }

    > div:first-of-type {
      margin-top: 0 !important;
    }

    > footer {
      padding: 30px 60px !important;
      position: relative !important;
    }
  }

  &.shown {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  button > i {
    display: block;
  }

  > header {
    padding-bottom: 0 !important;
    button {
      color: #4c4c4c !important;
      height: 40px !important;
      font-size: 18px !important;
      line-height: 40px !important;
      margin-left: -15px;
      width: 40px !important;
    }

    h1 {
      color: #242424;
      font: 300 30px/40px $baseFont;
      margin: 8px 0 0;
    }
  }

  > form {
    flex-grow: 1;
    overflow: auto;
    padding-top: 30px;
    > div {
      padding: 0 30px;
    }
  }

  #keywords > div {
    background: #f5f5f5;
    border: 2px solid transparent;
    border-radius: 3px;
    box-shadow: none !important;
    box-sizing: border-box;
    font: 15px/18px "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
    padding: 7px 10px;
    width: 100%;
    min-height: 54px;

    @include for-size(phone-only) {
      line-height: 15px;
      font-size: 14px;
    }

    > div {
      padding: 0 8px;
    }

    input[type="text"] {
      margin-bottom: 0;
    }
  }

  > .panel {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    &.open {
      display: block;
    }

    @include for-size(tablet-landscape-up) {
      background: transparent;
      position: absolute;
      left: -30px;
      right: -30px;
      bottom: -30px;
      > div {
        border-radius: 4px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
        width: 300px;
        left: auto !important;
        top: auto;
        right: 30px !important;
        bottom: 30px !important;
        > button.close {
          display: none;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      > button {
        margin: 30px;
        margin-top: 15px;
        margin-bottom: 30px;
        border: 2px solid #e3e3e3;
        line-height: 50px;
        border-radius: 50px;
        font-size: 16px;
        color: #dd3b26;
        &:first-child {
          margin-top: 30px;
          margin-bottom: 0;
        }
        &.is-primary {
          color: #fff;
          border-color: transparent;
        }
        &.close {
          border: 0;
          border-top: 1px solid #dbdbdb;
          border-radius: 0;
          color: $brandColor;
          margin: 0;
        }
      }
    }
  }

  > header {
    padding: 30px;
    @include for-size(tablet-landscape-up) {
      display: none;
    }
  }

  > footer {
    padding: 15px 30px;
    border-top: solid 1px #dfdfdf;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 50px;

    @include for-size(tablet-landscape-up) {
      display: block;
      > button:first-child {
        margin-right: 30px !important;
      }
    }

    > button {
      background: none;
      border-radius: 25px;
      color: #9e9e9e;
      cursor: pointer;
      font: 500 16px $baseFont;
      height: 50px;
      width: 50%;
      @include for-size(tablet-landscape-up) {
        max-width: 100px;
        &.is-primary {
          max-width: 200px;
        }
      }
      &.is-primary {
        margin-right: 0;
      }
      &.more {
        width: 50px;
        min-width: 50px;
        float: right;
        border: 2px solid #e3e3e3;
        color: $brandColor;
      }
    }
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    label {
      flex-grow: 1;
      width: 100%;
    }
    > div {
      flex-grow: 1;
      @include for-size(tablet-landscape-up) {
        &:first-child {
          margin-right: 15px;
        }
        &:last-child {
          // margin-left: 15px;
        }
      }
    }
  }

  .rdtPicker {
    position: unset !important;
    width: 250px;
  }
}

.rdt.icon-date {
  > input {
    padding-left: 48px;
  }
  &::before {
    color: #555;
    left: 20px;
    position: absolute;
    top: 18px;
  }
}

.MMSPanel {
  justify-content: center;
  color: #707070;
  font: 16px/70px $baseFont;
  align-items: center;
  align-self: center;
  min-height: 50px;
  width: 100%;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  z-index: 101;

  .filePreview {
    background: #242424;
    border-radius: 3px;
    color: #fff;
    display: block;
    object-fit: cover;
    text-align: center;
    text-transform: uppercase;
  }

  .imageDetails {
    align-items: center;
    background-color: #f2f2f2;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    box-sizing: border-box;
    color: #242424;
    display: flex;
    justify-content: space-between;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      > div {
        font: 15px/18px $baseFont;
        &:last-child {
          color: #787878;
          font: 13px/16px $baseFont;
          margin-top: 5px;
        }
      }
    }
  }

  .badge {
    position: absolute;
    right: 6px;
    top: 0px;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    font-size: 8px;
    background: rgb(0, 119, 200);
    color: white;
    font-weight: bold;
    padding-top: 1px;
    box-sizing: border-box;
  }
  .badget-span {
    position: absolute;
    top: 0;
    right: 0;
    background: #ef4902;
    width: 17px;
    color: #fff;
    height: 17px;
    border-radius: 50%;
    z-index: 5;
    font: 10px/17px "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
  }
  .options {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    padding: 0 30px;
    > button {
      position: relative;
    }

    > button[type="button"] {
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      font-size: 1.25em;
      outline: none;
      padding: 0;
    }

    > button[type="button"] {
      background: none;
      color: $brandColor;
      height: 2em;
      margin: 0;
      width: 2em;
      min-width: 2em;
      padding: 0;
      font-size: 1.25em;
      &:hover {
        background: #fafafa;
      }
    }
  }
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover {
  border-color: var(--calender-hover-color);
  cursor: pointer;
  background: #eeeeee;
}
.rdtPrev,
.rdtSwitch,
.rdtNext {
  color: var(--calender-hover-color) !important;
}
.rdtToday {
  &::before {
    border-bottom: 7px solid var(--calender-hover-color) !important;
  }
}

.rdtTimeToggle {
  border: 2px solid #e3e3e3 !important;
  color: var(--calender-hover-color) !important;
  border-radius: 30px !important;
}
.rdtPicker .rdtTimeToggle:hover {
  border-color: #e3e3e3;
  cursor: pointer;
  background: #eeeeee;
}

.icon-date {
  > input[type="text"]:focus {
    background-color: white;
    border-color: var(--calender-hover-color) !important;
  }
}

a {
  color: #1c85c4;
  margin-left: 25px;
  cursor: pointer;
  text-decoration: underline;
}
