@import "../../mixins.scss";

.SchedulerPanel {
  height: 100%;
  .tooltipContainer {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 300px;

    @include for-size(phone-only) {
      width: 272px;
      &:after {
        left: 36%;
      }
    }
  }

  .tooltipRight {
    @include for-size(phone-only) {
      &:after {
        left: 90%;
      }
    }
  }

  .tooltipContent {
    width: 300px;
    line-height: 1.25;
    text-transform: initial;

    ul {
      padding-left: 8px;
      list-style: none;
      i {
        color: #fff;
        margin-right: 4px;
      }
      i.icon-phone {
        filter: invert(100%);
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .inner > header {
    background: rgb(250, 250, 250);
    padding: 13px 0px;
    min-height: 44px;
    border-bottom: 1px solid rgb(227, 227, 227);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
    h1 {
      margin: 0;
    }
  }
  .inner > ul:not(.scheduler-list) {
    margin: 0;
    list-style: none;
    border-bottom: solid 1px #e4e4e4;
    text-transform: uppercase;
    font: 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgba(36, 36, 36, 0.4);
    min-height: 56px;
    padding: 0 15px;
    display: flex;
    justify-content: space-around;
    li {
      display: inline-block;
      margin-left: 8px;
      border-color: transparent;
      cursor: pointer;
      padding: 20px 0;
      position: relative;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        font-weight: 500;
        &:before {
          height: 0px;
          border-bottom: solid 3px;
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
        }
      }
    }
  }
  .icon-close-position {
    position: absolute;
    right: 20px;
    top: 27px;
    cursor: pointer;
    color: #7a7a7a;
  }
}

ul.scheduler-list {
  list-style: none;
  padding: 15px;
  margin: 0;
  max-height: 100%;
  overflow: auto;
  flex: 1;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  li {
    margin-bottom: 15px;
  }
}
.info {
  padding: 12px;
  border: 1px solid #004b87;
  border-radius: 14px 0 14px 14px;
  // display: table;
  // margin-right: 0;
  // margin-left: auto;
  p {
    margin: 0;
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 20px;
    .bold {
      font-weight: bold;
      margin-right: 3px;
    }
  }
}

.edit-schedule {
  margin-bottom: 8px;
  align-items: center;
  justify-content: flex-end;
  color: rgba(161, 161, 161, 0.9);
  font: 13px "Helvetica Neue", Helvetica, Arial, sans-serif;
  white-space: pre-wrap;
  display: grid;
  grid-template-columns: 20px 160px 20px;
  grid-gap: 10px;
  line-height: 1.4;
  letter-spacing: 0.5px;
  &.schedule-recurring {
    grid-template-columns: 20px 268px 20px;
  }
  &.schedule-recurring-single {
    grid-template-columns: 20px 135px 20px;
  }
  a {
    color: #1c85c4;
    margin-left: -8px;
    cursor: pointer;
    text-decoration: underline;
  }
  i {
    margin-right: 5px;
  }
}

.high-light-theme {
  border-radius: 7px;
  background-color: #b1d5e5;
}
