@import "../../mixins.scss";

div.ConversationList {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  font: 13px/1.5 $baseFont;
  color: #7e7e7e;
  text-align: center;
  &.search {
    padding-top: 2em;
    justify-content: start;
  }
}
ul.ConversationsList {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  li {
    color: #242424;
    font: 14px/40px "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0 20px 0 50px;
    position: relative;
    user-select: none;
    cursor: pointer;
    border-bottom: solid 1px #f7f7f7;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include for-size(tablet-landscape-up) {
      padding: 0 30px 0 60px;
    }
    &.swiped {
      > span {
        opacity: 0;
      }
      > .ConversationList__actions {
        transform: translateX(-77px);
      }
    }

    > .ConversationList__actions {
      left: 100%;
      position: absolute;
      top: 0;
      // transition: all .5s ease;
      text-align: right;
      width: 47px;

      > button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin: 10px 0 10px 7px;
        font-size: 20px;
        &:first-child {
          margin-left: 0;
        }
        &.is-primary i:before {
          color: $brandColor !important;
        }
      }
    }

    > span {
      display: flex;
      align-items: center;
      color: #a5a5a5;
      font-size: 13px;
      float: right;
      transition: all 0.2s ease;

      > .ConversationsList__Badge {
        width: 18px;
        height: 18px;
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ef4902;
        margin-left: 8px;

        > span {
          color: #fff;
          font-size: 10px;
          font-weight: bold;
        }
      }
    }
    > i {
      margin-right: 15px;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 20px;
      @include for-size(tablet-landscape-up) {
        left: 30px;
      }
      &.icon-broadcast {
        left: 18px;
        @include for-size(tablet-landscape-up) {
          left: 28px;
        }
      }
    }
    &:before {
      border-bottom: solid 1px #ebebeb;
      content: "";
      display: none;
      width: 100%;
    }
    &.active {
      background: #e9e9e9 !important;
      &:before,
      & + li:before {
        border-color: transparent;
      }
    }

    &.unread {
      font-weight: bold;
    }

    &:hover {
      background: #f8f8f8;
      @include for-size(tablet-landscape-up) {
        > span {
          opacity: 0 !important;
        }
        > .ConversationList__actions {
          transform: translateX(-77px) !important;
        }
      }
    }
    &.active:before,
    &.active + li:before,
    &:hover:before,
    &:hover + li:before,
    &:first-child::before {
      border-color: transparent;
    }
  }
}
.con-panel {
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  ul {
    height: calc(100% - 36px);
    overflow: auto;
    margin-bottom: 0px;
    transition: all 0.2s ease-in-out;
    &.open {
      height: calc(55% - 36px);
    }
  }
  .cus-collapse {
    background: #fafafa;
    padding: 10px 30px 6px;
    border-top: 1px solid #ddd;
    text-align: right;
    display: none;
    span {
      font-weight: bold;
      float: left;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    i {
      display: inline-block;
      font-size: 20px;
      color: $brandColor !important;
    }
    & + ul {
      height: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
    }
    &.open {
      display: block;
      & + ul {
        height: calc(45% - 36px);
        overflow: auto;
      }
    }
  }
}
