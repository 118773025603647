@import '../../mixins.scss';

.SearchResults {
  display: none;

  > section {
    padding: 0 30px 30px;
    font-family: $baseFont;
  }

  &__Date {
    color: #a1a1a1;
    > i {
      color: #555;
      margin-right: 10px;
    }
  }

  &__Messages {
    list-style: none;
    padding: 0;
    border: solid 1px #c8c8c8;
    margin-bottom: 40px;
    > li {
      background: #fbfbfb;
      color: #707070;
      cursor: pointer;
      font: 14px/20px $baseFont;
      padding: 15px 30px;
      > div {
        color: #242424;
        font: 500 16px $baseFont;
        margin-bottom: 10px;
        > i.icon-outbound {
          color: $brandColor;
          margin: auto 20px;
        }
      }
      &:hover {
        background: #fff;
      }
      &:not(:first-child) {
        border-top: solid 1px #c8c8c8;
      }
      b {
        color: $brandColor;
      }
    }
  }

  @include for-size(tablet-landscape-up) {
    position: relative !important;
    display: block;
    min-width: 600px;
    
    > header {
      button, h1 {
        display: none !important;
      }
    }

    > form {
      background: #fff;
      border: 1px solid #e4e4e4;
      margin: 30px 60px;
    }

    > div:first-of-type {
      margin-top: 0 !important;
    }

    > footer {
      padding: 30px 60px !important;
      position: relative !important;
    }
  }

  &.shown {
    display: flex;
    flex-direction: column;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    flex-grow: 1;
    overflow: auto;
  }

  button > i {
    display: block;
  }

  > header {
    padding-bottom: 0 !important;
    button {
      color: #4c4c4c !important;
      height: 40px !important;
      font-size: 18px !important;
      line-height: 40px !important;
      margin-left: -15px;
      width: 40px !important;
    }
  
    h1 {
      color: #242424;
      font: 30px/40px $baseFont;
      margin: 8px 0 0;
    }
  }

  > form {
    flex-grow: 1;
    overflow: auto;
    padding-top: 30px;
    > div {
      padding: 0 30px;
    }
  }

  #keywords > div {
    background: #f5f5f5;
    border: 2px solid transparent;
    border-radius: 3px;
    box-sizing: border-box;
    font: 15px/18px "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
    padding: 5px 10px;
    width: 100%;
  }

  > .panel {
    display: none;
    background: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    &.open {
      display: block;
    }

    @include for-size(tablet-landscape-up) {
      background: transparent;
      position: absolute;
      left: -30px;
      right: -30px;
      bottom: -30px;
      > div {
        border-radius: 4px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
        width: 300px;
        left: auto !important;
        top: auto;
        right: 30px !important;
        bottom: 30px !important;
        > button.close {
          display: none;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      > button {
        margin: 30px;
        margin-top: 15px;
        margin-bottom: 30px;
        border: 2px solid #e3e3e3;
        line-height: 50px;
        border-radius: 50px;
        font-size: 16px;
        color: #DD3B26;
        &:first-child {
          margin-top: 30px;
          margin-bottom: 0;
        }
        &.is-primary {
          color: #fff;
          border-color: transparent;
        }
        &.close {
          border: 0;
          border-top: 1px solid #dbdbdb;
          border-radius: 0;
          color: $brandColor;
          margin: 0;
        }
      }
    }
  }

  > header {
    padding: 30px;
    @include for-size(tablet-landscape-up) {
      display: none;
    }
  }

  > footer {
    padding: 15px 30px;
    border-top: solid 1px #dfdfdf;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 50px;

    @include for-size(tablet-landscape-up) {
      display: block;
      > button:first-child {
        margin-right: 30px !important;
      }
    }

    > button {
      background: none;
      border-radius: 25px;
      color: #9e9e9e;
      cursor: pointer;
      font: 16px $baseFont;
      height: 50px;
      &.is-primary {
        margin-right: 0;
      }
      &.more {
        width: 50px;
        float: right;
        border: 2px solid #e3e3e3;
        color: $brandColor;
      }
    }
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    label {
      flex-grow: 1;
      width: 100%;
    }
    > div {
      flex-grow: 1;
      @include for-size(tablet-landscape-up) {
        &:first-child {
          margin-right: 15px;
        }
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
}

.rdt.icon-date {
  > input {
    padding-left: 48px;
  }
  &::before {
    color: #555;
    left: 20px;
    position: absolute;
    top: 18px;
  }
}