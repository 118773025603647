@import "../../mixins.scss";

.template-btn {
  display: inline-flex;
  position: relative;
  line-height: normal;
}
.template-keyword {
  position: relative;
  left: 100px;
  top: 0px;
  width: 275px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 5px 1px #ddd;
  z-index: 150;
  height: 250px;
  overflow-y: scroll;
  line-height: 20px;
  &.open-up {
    position: absolute;
    top: -277px;
    left: 55px;
  }
}

.template-keyword::after {
  border-color: #ddd transparent;
  border-style: solid;
  border-width: 0px 9px 9px;
  top: -10px;
  content: "";
  height: 0;
  position: absolute;
  left: 15px;
  bottom: -6px;
  width: 0;
}
.template-keyword h4 {
  font: 18px/20px $baseFont;
  margin: 15px 0;
  line-height: normal;
  font-weight: 600;
  color: black;
}
.template-keyword mark {
  margin-right: 8px;
  cursor: pointer;
  background-color: #b1d5e5;
  border-radius: 5px;
}
.template-btn button {
  font-size: 1.25em;
  width: 2em;
  height: 2em;
}
.template-keyword p {
  margin: 0px 0px 10px 0px !important;
}
