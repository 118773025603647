@import "../../mixins.scss";

.ListProfile {
  box-sizing: border-box;
  display: none;
  overflow: auto;
  padding: 30px;
  width: 100%;
  z-index: 1002;

  @include for-size(tablet-landscape-up) {
    display: block !important;
    position: relative !important;

    > button {
      display: none;
    }

    > header {
      margin-top: 0 !important;
      text-align: left !important;
    }

    > footer {
      border-top: 0 !important;
      text-align: left !important;
      padding: 0 !important;

      > button:after {
        content: none !important;
      }
    }
    &__Members {
      height: auto !important;
    }
  }

  @include for-size(phone-only) {
    width: 100%;
  }

  &__Header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 4px;

    > h3 {
      color: #a1a1a1;
      font: 12px $baseFont;
      margin-bottom: 14px;
      margin-top: 10px;
    }
  }

  &__SortLabel {
    display: flex !important;
    justify-content: flex-end;
    position: relative;
    margin-top: 6px;

    > b {
      margin: 0 6px;
    }

    > span {
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      font-weight: 500;

      &:first-child {
        color: #a1a1a1;
        font-weight: 200;
      }
    }
  }

  &__Select {
    position: absolute;
    width: 200px !important;
    background-color: white;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    top: 24px;
    right: 0;
    padding: 12px;

    > span {
      display: block;
      width: 100%;
      padding: 0 8px;
      box-sizing: border-box;
      text-align: end;
      color: #242424;
      font-size: 16px;
      line-height: 32px;

      &:hover {
        background-color: #dedede;
      }
    }
  }

  &__Members {
    overflow: auto;
    height: 100%;
    .csv-container {
      background-color: #f5f5f5;
      display: block;
      padding: 20px 30px;
      > a {
        display: block;
        color: #242424;
        font-size: 16px;
        font-weight: bold;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > h3 {
        color: #4f4f4f;
        font-size: 13px;
      }
      > h4 {
        color: #828282;
        font-size: 11px;
      }
    }

    > div div {
      box-sizing: border-box;
      color: #242424;
      display: block;
      font: 14px $baseFont;
      margin-bottom: 7px;
      padding-right: 1em;
      text-overflow: ellipsis;
      width: 100%;
      margin-bottom: 12px;
      white-space: nowrap;
      > i {
        margin-right: 15px;
      }
      > img {
        margin-left: 12px;
      }
    }
  }

  button > i {
    display: block;
  }

  > button {
    color: #4c4c4c !important;
    height: 40px !important;
    font-size: 18px;
    line-height: 40px;
    margin-left: -15px;
    width: 40px !important;
  }

  i.icon-back {
    font-size: 18px;
    line-height: 40px;
  }

  &.shown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    max-height: 100%;
  }

  > footer {
    text-align: center;
    border-top: solid 1px #dfdfdf;
    padding: 40px 0 64px;

    > button {
      background: none;
      border: 2px solid #ebebeb;
      border-radius: 50%;
      cursor: pointer;
      font-size: 1rem;
      height: 54px;
      width: 54px;
      margin-right: 30px;
      position: relative;

      @include for-size(tablet-landscape-up) {
        margin-right: 10px;
      }

      &.text-btn::after {
        content: "Text";
      }

      &.edit-btn::after {
        content: "Edit";
      }

      &.delete-btn::after {
        content: "Delete";
      }

      &:after {
        color: #707070;
        font: 12px $baseFont;
        text-align: center;
        text-transform: uppercase;
        display: block;
        margin-top: 12px;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  header {
    margin-top: 34px;

    h1,
    h2 {
      margin: 0;
      font-weight: 400;
    }

    h1 {
      color: #242424;
      font-family: $baseFont;
      font-size: 26px;
      line-height: 32px;
    }

    h2 {
      color: #7a7a7a;
      display: inline-block;
      font-family: $baseFont;
      font-size: 18px;
      line-height: 20px;
      margin-top: 10px;
    }

    label {
      border: 1px solid #ebebeb;
      border-radius: 15px;
      color: #7e7e7e;
      display: inline-block;
      font-family: $baseFont;
      font-size: 10px;
      line-height: 20px;
      margin: 0;
      margin-right: 5px;
      padding: 0px 8px;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }

  > div {
    margin-bottom: 30px;
    > p {
      word-break: break-all;
      color: #242424;
      font: 14px $baseFont;
    }
  }

  .listTooltip {
    padding: 12px 20px;
  }
}
