@import '../../mixins.scss';

aside.SidePanel {
  background: $brandColor;
  display: none;
  height: 100%;
  // overflow: auto;
  width: 70px;

  @include for-size(tablet-landscape-up) {
    display: block;
  }

  > button {
    background: none;
    border: 0;
    color: #fff;
    font-size: 1.25em;
    height: 70px;
    padding: 0;
    width: 70px;
    outline: none;
    cursor: pointer;
    position: relative;

    &.disabled {
      cursor: default;
      opacity: .25 !important;
    }

    &:hover:not(.disabled) {
      background: rgba(0,0,0,.2);
      opacity: 1;
      .tooltip {
        // display: block;
        transform: translateY(-50%) scale(1);
        opacity: 1;
        margin-left: 20px;
      }
    }
  }
}