@import "../../mixins.scss";

aside.EmojiPanel {
  background: #fff;

  left: 0;
  line-height: normal;
  height: 0;
  max-height: 40vh;
  overflow-y: auto;
  right: 0;
  text-align: center;
  transition: all 0.5s ease;
  z-index: 100;
  &.openDown {
    top: 100%;
  }
  &.openTop {
    bottom: 100%;
    position: absolute;
  }
  &.shown {
    border-bottom: solid 1px #e3e3e3;
    border-top: solid 1px #e3e3e3;
    height: 40vh;
  }

  @include for-size(tablet-landscape-up) {
    display: block;
  }

  > button {
    margin: 0 !important;
  }

  > .emoji-mart {
    width: 100% !important;
    border: 0 !important;
    height: 100%;
    > .emoji-mart-bar {
      button {
        border-radius: 0;
      }
    }
    > .emoji-mart-scroll {
      height: calc(100% - 82px);
    }
  }
}
