@import '../../../mixins.scss';

.ProfileEditModal {

  &__Tabs + div {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;

    @include for-size(tablet-landscape-up) {
      min-width: 560px;
      padding: 40px 60px;
      overflow-y: initial;
      footer {
        border-top: 0 !important;
        margin-top: 30px !important;
      }
    }

    footer {
      border-top: solid 1px #dfdfdf;
      padding: 15px 30px;
      @include for-size(tablet-landscape-up) {
        padding: 15px 0;
      }
      > button {
        width: 50%;
        @include for-size(tablet-landscape-up) {
          max-width: 100px;
          &.is-primary {
            max-width: 200px;
            margin-right: 30px;
          }
        }
      }
    }

    form {
      padding: 30px;
      flex-grow: 1;
      overflow-y: auto;

      @include for-size(tablet-landscape-up) {
        padding: 0 !important;
      }
      > .columns {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-items: stretch;
        
        &:first-child > .column:first-child {
          margin-top: 0;
        }

        @include for-size(tablet-landscape-up) {
          flex-direction: row;
          &:first-child {
            margin-top: 0 !important;
          }
          > .column {
            box-sizing: border-box;
            margin-top: 0 !important;
            width: 50%;
            padding-left: 20px;
            padding-right: 20px;
            &:first-child {
              padding-left: 0;
            }
            &:last-child:not(.one-half) {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  &__Tabs {
    margin: 0;
    margin-top: -20px;
    border-bottom: solid 1px #e4e4e4;
    list-style: none;
    padding: 0 60px;
    color: #a1a1a1;
    text-transform: uppercase;
  }
}
  