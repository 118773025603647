@import '../../../mixins.scss';

.SearchModal {
  
  > div.inner {
    display: flex;
    flex-direction: column;
    height: 100%;

    header h1 {
      margin-top: 40px;
      @include for-size(tablet-landscape-up) {
        margin-top: 0;
        margin-left: 70px;
      }
      > b {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        max-width: 93%;
        vertical-align: bottom;
      }
      > p {
        color: #7a7a7a;
        font: 15px/18px $baseFont;
        margin: 0;
        > span {
          color: #000;
        }
      }
    }

    @include for-size(tablet-landscape-up) {
      min-width: 560px;
      padding: 40px 60px;
      overflow-y: initial;
      > header {
        padding: 0 0 40px;
      }
      footer {
        border-top: 0 !important;
      }
    }

    footer {
      border-top: solid 1px #dfdfdf;
      padding: 15px 30px;
      > button {
        width: 50%;
        margin: 0 !important;
      }
      @include for-size(tablet-landscape-up) {
        padding: 15px 0;
        & > button.is-primary {
          max-width: 200px;
          margin-right: 30px !important;
        }
        & > button:not(.is-primary) {
          max-width: 100px;
        }
      }
    }

    form {
      padding: 0 30px 30px;
      flex-grow: 1;
      overflow-y: auto;

      @include for-size(tablet-landscape-up) {
        padding: 0 !important;
      }
      > .columns {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-items: stretch;
        
        &:first-child > .column:first-child {
          margin-top: 0;
        }

        @include for-size(tablet-landscape-up) {
          flex-direction: row;
          &:first-child {
            margin-top: 0 !important;
          }
          > .column {
            box-sizing: border-box;
            margin-top: 0 !important;
            width: 50%;
            padding-left: 20px;
            padding-right: 20px;
            &:first-child {
              padding-left: 0;
            }
            &:last-child:not(.one-half) {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  
}
  