@import "../../../mixins.scss";

.ListEdit {
  display: none;

  @include for-size(tablet-landscape-up) {
    display: block;
    min-width: 410px;
    position: relative !important;
    width: 100%;

    > footer {
      border: 0 !important;
      padding: 0 !important;
      position: relative !important;
    }
  }

  &.shown {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    z-index: 100;
  }

  &__Scroll {
    padding: 30px;
    overflow-y: scroll;
    height: calc(100% - 81px - 60px);

    button > i {
      display: block;
    }

    > button {
      color: #4c4c4c !important;
      height: 40px !important;
      font-size: 18px !important;
      line-height: 40px !important;
      margin-left: -15px;
      width: 40px !important;
    }

    > h1 {
      color: #242424;
      font: 300 30px/40px $baseFont;
      margin: 8px 0 36px;
    }

    > div {
      font-size: 0;
    }

    > div:not(:first-child) {
      margin-top: 20px;
    }

    label {
      width: 100%;
    }

    .csv-upload-hint {
      display: block;
      font-size: 12px;
      color: #999;
      a {
        color: $brandColorLighten;
        text-decoration: none;
        font-weight: 600;
      }
    }

    .dragndrop-container {
      margin: 10px 0 25px;
    }

    @include for-size(tablet-landscape-up) {
      > button,
      > h1 {
        display: none !important;
      }

      > div:first-of-type {
        margin-top: 0 !important;
      }

      label {
        width: auto;
      }

      label:nth-child(3) {
        margin-left: 50px;
      }
    }
  }

  > footer {
    padding: 15px 30px;
    border-top: solid 1px #dfdfdf;
    background: #fff;
    > button {
      font-weight: 500;
      margin: 0 !important;
      width: 50%;
    }
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    label {
      flex-grow: 1;
      width: 100%;
    }
    :not(:first-child) {
      flex: 1;
    }
    input:last-child {
      margin-left: 15px;
    }
  }

  button {
    background: none;
    border-radius: 25px;
    color: #9e9e9e;
    cursor: pointer;
    font: 16px $baseFont;
    height: 50px;
    width: 100px;
  }

  #members {
    font: 15px $baseFont;
  }
}
