@import "../../../mixins.scss";

.modal {
  background: rgba(0, 0, 0, 0.55);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;

  @include for-size(tablet-landscape-up) {
    overflow: auto;
  }

  > .inner {
    align-self: center;
    background: #fff;
    box-sizing: border-box;
    // height: 100%;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    // min-height: 100%;
    &.set_height {
      min-height: 100%;
      height: 100%;
    }
    @include for-size(tablet-landscape-up) {
      width: 75%;
      max-width: 900px;
    }

    > ul {
      margin: -20px 0 0;
      list-style: none;
      border-bottom: solid 1px #e4e4e4;
      text-transform: uppercase;
      font: 13px $baseFont;
      color: rgba(36, 36, 36, 0.4);
      min-height: 56px;
      padding: 0 30px;

      @include for-size(tablet-landscape-up) {
        padding: 0 60px;
      }

      > li {
        display: inline-block;
        margin-left: 40px;
        border-color: transparent;
        cursor: pointer;
        padding: 20px 0;
        position: relative;
        font: 13px $baseFont;

        &.active {
          color: $brandColor;
          font-weight: 500;
          &:before {
            height: 0px;
            border-bottom: solid 3px;
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
          }
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    > section {
      background: #fff;
      display: flex;
      flex-grow: 1;
      padding: 0;
      justify-content: stretch;
    }

    > header {
      padding: 30px;
      z-index: 100;

      @include for-size(tablet-landscape-up) {
        padding: 40px 60px;
      }

      > h1 {
        color: #000;
        font: 300 30px/40px $baseFont;
        margin: 0;
      }

      button {
        background: none;
        border: 0;
        color: #4c4c4c;
        cursor: pointer;
        font-size: 1.2em;
        height: 40px;
        line-height: 1;
        user-select: none;
        width: 40px;
        > i {
          display: block;
          line-height: 40px;
        }
        &.back-button {
          float: left;
          margin-right: 30px;
        }
        &.close-button {
          float: right;
        }
      }
    }
  }
}
