@import "../../mixins.scss";

aside.TemplatePanel {
  background: #fff;
  border-top: solid 1px #e3e3e3;
  // bottom: 100%;
  left: 0;
  line-height: normal;
  height: 0;
  max-height: 25vh;
  overflow-y: auto;
  // position: absolute;
  right: 0;
  transition: all 0.5s ease;
  z-index: 100;
  &.openDown {
    top: 100%;
    position: relative;
  }
  &.openTop {
    bottom: 100%;
    position: absolute;
  }
  &.shown {
    border-bottom: solid 1px #e3e3e3;
    height: auto;
  }

  > button {
    // float: right;
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin: 10px 0px 0px 0px !important;
    display: block;
    color: #7a7a7a;

    left: 97%;
    position: relative;
  }
  i {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    color: #7a7a7a;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 40px;
    li {
      color: #242424;
      cursor: pointer;
      font: 14px/40px $baseFont;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #ddd;
      &:hover {
        background: #f8f8f8;
        b {
          background: #00bbe5;
          color: #fff;
        }
      }

      b {
        background: #eaeaea;
        color: #636363;
        display: inline-block;
        font: 500 10px/12px $baseFont;
        text-transform: uppercase;
        border-radius: 5px;
        margin: 10px;
        padding: 10px;
        width: 150px;
      }

      // span {
      //   padding: 10px 10px;
      //   width: 200px;
      // }
      .high-light-theme {
        border-radius: 7px;
        background-color: #b1d5e5;
      }
      .flex-container {
        display: flex;
      }
      .border-temp {
        // border: 1px solid #ddd;
        background-color: white;
        border-radius: 7px;
        padding: 10px 0px 10px 10px;
        width: 80%;
        margin: 10px;
        line-height: 20px;
        white-space: pre-wrap;
        z-index: 150;
      }
      .arrow-left {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        // border-right: 10px solid blue;
        position: relative;
        top: 17px;
        left: 10px;
      }
    }
  }

  @include for-size(tablet-landscape-up) {
    display: block;
  }
}
