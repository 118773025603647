@import "../../../mixins.scss";

.dragndrop-container {
  position: relative;
  font-family: $baseFont;
  overflow-y: scroll;
  .inner {
    color: #666;
    border: 2px dashed #e3e3e3;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: table;

    &.hover {
      border-color: $brandColor;
    }
    &.table {
      border: none;
    }
    .placeholder {
      font-size: 14px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      padding: 10px 30px;
      overflow: auto;

      > div:last-child {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .upload-hint {
    font-size: 14px;
    text-align: center;
    padding: 10px;

    span {
      color: #00bbe5;
      &:hover {
        cursor: pointer;
      }
    }
  }
  input[type="file"] {
    display: none;
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: stretch;
    .cell {
      border-left: 1px solid $brandColorLighten;
      border-bottom: 1px solid $brandColorLighten;
      padding: 4px 8px;
      width: 25%;
      overflow: hidden;
      text-align: left;
      &.th {
        font-weight: bold;
        // padding: 10px 0;
        color: #fff;
        background-color: $brandColor;
        text-align: center;
      }
    }
    .cell:last-child {
      border-right: 1px solid $brandColorLighten;
    }
    .cell:first-child {
      font-weight: 600;
    }
    .cell:not(first-child) {
      font-size: 12px;
    }
  }
  .text-left {
    text-align: left;
  }
}
