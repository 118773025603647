@import '../../mixins.scss';

ul.RepliesList {
  background: #fff;
  display: none;
  list-style: none;
  margin: 0;
  padding: 20px 0;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  &.shown {
    display: block;
  }
  @include for-size(tablet-landscape-up) {
    background: #f6f6f6;
    box-sizing: border-box;
    padding: 30px 60px;
    li {
      border: 1px solid #E4E4E4;
      margin-bottom: 10px;
      padding: 10px 48px 10px 30px;
      &::before {
        content: none !important;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  li {
    align-items: center;
    background: #fff;
    color: #242424;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font: 15px/40px $baseFont;
    justify-content: space-between;
    padding: 10px 48px 10px 30px;
    position: relative;
    user-select: none;

    &.no {
      background: transparent;
      border: 0;
      color: $noItemsTextColor;
      cursor: default;
      height: 100%;
      justify-content: center;
      padding: 0;
    }

    &:not(.no)::after {
      color: #7a7a7a;
      content: '';
      font-family: $iconFont !important;
      position: absolute;
      right: 22px;
      transform: rotate(180deg);
    }
    > i {
      margin-right: 15px;
    }
    &:not(.no)::before {
      border-bottom: solid 1px #ebebeb;
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
    }
    &.active {
      background: #e9e9e9 !important;
      &:before, & + li:before {
        border-color: transparent;
      }
    }
    &:hover:not(.no) {
      background: #f8f8f8;
      @include for-size(tablet-landscape-up) {
        background: #fff;
      }
    }
    &.active:before, &.active + li:before,
    &:hover:before, &:hover + li:before,
    &:first-child::before {
      border-color: transparent;
    }
  }
}

div.react-switch-bg {
  border-radius: 40px !important;
  i {
    font: normal 600 14px/40px Arial, sans-serif;
    color: #fff;
    display: block;
    height: 40px;
    text-align: center;
  }
}
