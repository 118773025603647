@import '../../../mixins';

.SignUpForm {
  > .ProfileAccount__PasswordRequirements {
    position: absolute;
    left: 85%;
    width: 200px;
    margin-top: -7%;
  }
  
  > p {
    color: #828282;
    font: 14px/22px 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-top: 0;
    margin-bottom: 30px;
  }

  button.submit {
    margin-top: 30px !important;
  }
}
  