@import "../../mixins.scss";
.infoResult {
  color: #828282;
  font: 13px/22px $baseFont;
  padding: 0 30px;
  margin: 0 auto;
  max-width: 320px;
}
.centered {
  position: fixed;
  top: 45%;
  left: 50%;
  // transform: translate(-50%, -50%);
}
.appName {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}
.AuthView {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: 100%;
  & .panel {
    align-items: stretch;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0;
    position: relative;
    width: 100%;
    min-width: 295px;
    img {
      max-width: 300px;
      max-height: 150px;
      margin: 0 auto;
    }
    div:last-child {
      text-align: center;
    }
    @include for-size(tablet-landscape-up) {
      width: 500px;
      padding: 60px 0;
    }

    & .bottom-logo {
      img {
        max-width: 100%;
      }
      color: #b8b8b8;
      font: 14px/17px $baseFont;
      text-align: center;
    }

    & .form {
      display: block;
      margin: 0 auto;
      width: 100%;
      padding: 0 30px;
      max-width: 320px;
      box-sizing: border-box;

      > a {
        color: #353535;
        display: block;
        font: bold 14px/25px $baseFont;
        text-align: center;

        @include for-size(tablet-landscape-up) {
          text-align: left;
        }
      }

      > input[type="checkbox"] + label {
        margin: 10px 0 30px;
        justify-content: center;

        @include for-size(tablet-landscape-up) {
          margin: 10px 0 40px;
          justify-content: left;
        }
      }

      & > h1 {
        color: #444;
        font: 300 24px $baseFont;
        margin: 0 0 30px;
        text-align: center;

        @include for-size(tablet-landscape-up) {
          font-size: 30px;
          line-height: 36px;
          margin: 0 0 40px;
          text-align: left;
        }
      }

      & .field {
        position: relative;
        button {
          background: none;
          border: 0;
          color: #c4c4c4;
          cursor: pointer;
          height: 50px;
          padding: 0;
          position: absolute;
          right: 0;
          width: 50px;
          > svg {
            width: 22px;
            height: 12px;
          }
        }
      }

      .info {
        color: #828282;
        font: 13px/22px $baseFont;
      }

      & > input {
        display: block;
      }

      & .input {
        margin-bottom: 10px;
      }

      & > .submit {
        background: $brandColor;
        border: 0;
        border-radius: 25px;
        color: #fff;
        display: block;
        font: 600 16px/50px $baseFont;
        margin: 20px auto 40px;
        padding: 0;
        width: 170px;

        @include for-size(tablet-landscape-up) {
          margin-left: 0;
        }
        &.disabled {
          pointer-events: none;
          background-color: #ccc;
        }
      }
    }
  }

  & .support {
    background: url("../../assets/messageview-auth-pic.jpg") no-repeat;
    background-size: cover;
    flex-grow: 1;
    display: none;

    @include for-size(tablet-landscape-up) {
      display: block;
    }
  }
}
