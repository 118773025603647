@import "../../../mixins.scss";

.bubble {
  text-align: left;

  > span {
    white-space: pre-wrap;
  }

  .media {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;

    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 2px;
    }
  }
}
.preview {
  svg {
    width: 80px;
    height: 80px;
  }
}

.filePreview {
  background: #242424;
  border-radius: 3px;
  color: #fff;
  display: block;
  object-fit: cover;
  text-align: center;
  text-transform: uppercase;
}

.imageDetails {
  align-items: center;
  background-color: #f2f2f2;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  box-sizing: border-box;
  color: #242424;
  display: flex;
  justify-content: space-between;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > div {
      font: 15px/18px $baseFont;
      &:last-child {
        color: #787878;
        font: 13px/16px $baseFont;
        margin-top: 5px;
      }
    }
  }
}

.media {
  .flex {
    align-items: center;
  }
}

.media {
  > a {
    text-decoration: none;
  }
  .filePreview {
    font: bold 10px/40px $baseFont;
    height: 40px;
    width: 40px;
    &.generic {
      font-size: 16px;
    }
  }
  .imageDetails {
    font: 13px/34px $baseFont;
    height: 34px;
    max-width: 275px;
    padding: 0 15px;
    > div {
      width: 100%;
    }
  }
}
