@import '../../mixins.scss';
$borderColor: #e3e3e3;

.ConversationsPanel {
  display: none;
  flex-direction: column;
  min-width: 320px;
  width: 100%;
  transition: all 1s ease;
  margin-left: 0;

  &:not(.shown) {
    margin-left: -100%;
  }

  &.shown {
    display: flex;
  }

  @include for-size(tablet-landscape-up) {
    border-right: 1px solid $borderColor;
    display: flex !important;
    margin-left: 0 !important;
    width: auto;
    max-width: 33%;
  }

  > .ConversationsList {
    flex-grow: 1;
    overflow-y: auto;
  }

  > header {
    background: #fafafa;
    padding: 13px 0;
    min-height: 44px;
    border-bottom: 1px solid $borderColor;

    @include for-size(tablet-landscape-up) {
      min-height: 44px;
    }
  }

  > footer .SearchInput {
    > .icon-search {
      left: 18px;
      right: auto;
      @include for-size(tablet-landscape-up) {
        left: 28px;
      }
    }
    > input {
      background: transparent;
      border-bottom: 1px solid #e3e3e3 !important;
      border-radius: 0;
      height: 44px;
      padding: 10px 20px 10px 50px;
      line-height: 1;
      border: 0;
      font-size: 14px;
      @include for-size(tablet-landscape-up) {
        padding: 10px 30px 10px 60px;
      }
    }
  }
}
