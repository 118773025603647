@import "../../mixins.scss";

ul.ListList {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  &.searchable {
    height: calc(100% - 74px);
  }

  @include for-size(tablet-landscape-up) {
    width: 340px;
    margin: 0 30px;
  }

  > li {
    color: #242424;
    font: 14px/40px $baseFont;
    padding: 0 30px;
    user-select: none;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: solid 1px #ebebeb;

    display: flex;
    align-items: center;

    &.active {
      background: #eee !important;
      &:before,
      & + li:before {
        border-color: transparent;
      }
    }
    &.disabled {
      cursor: default;
      opacity: 0.5;
    }

    > i {
      margin-right: 15px;
    }
    > img {
      margin-left: 12px;
    }

    &:not(.disabled):hover {
      background: #f8f8f8;
      &:before,
      & + li:before {
        border-color: transparent;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .contactTooltip {
    padding: 0 20px;
  }
}
